.ride-schedule__item__reviews {
  margin-top: 20px;
  a {
    color: $bcPurple;
  }

  p {
    margin-bottom: 5px;
  }

  hr {
    margin: 15px 0;
  }

  li:last-child hr {
    display: none;
  }
}

.ride-schedule__item__details__reviews a {
  color: $bcPurple;
  text-decoration: underline;
}