.dashboard-blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dashboard-stats__block {
  @include clearfix();
  @include borderWrapper();
  width: 100%;
  border-radius: 0;
  margin: 0 0 1% 0;

  @media (min-width: $screen-md) {
    width: 49%;
    margin: 0 1% 1% 0;
  }

  &:nth-child(even) {
    margin-right: 0;
  }

  h2, h3 {
    color: #3c3c3c;
  }

  h3 {
    margin-top: 0;
    text-transform: none;
  }

  img {
    max-width: 120px;

    @media (min-width: $screen-sm) {
      float: left;
    }
  }

  .col-sm-4 {
    float: none;
    width: auto;

    @media (min-width: 520px) {
      float: left;
      width: 33.3333333333%;
    }
  }

  &__value {
    margin-top: 10px;
    font-weight: bold;
    font-size: $font-size-h4;

    @media (min-width: $screen-sm) {
      float: left;
      margin-top: 10px;
      margin-left: 20px;
    }
  }

  [class^="col-"],
  [class*="col-"] {
    padding-bottom: 0;
  }

  .table-striped {
    margin: 20px 0 0;

    > tbody > tr > td {
      &:first-child {
        width: 48%;
      }

      &:nth-child(2) {
        width: 35%;
      }
    }
  }

  &-last {
    display: inline-grid;
    width: 100%;

    @media (min-width: 991px) {
      width: 49%;
    }

    > .dashboard-stats__block {
      width: auto;
      margin: 0 0 1%;

      @media (min-width: $screen-md) {
        margin: 0 0 2%;
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        margin-bottom: 2%;

        @media (min-width: 520px) {
          flex-direction: row;
        }
      }
    }

    > iframe {
      width: 100%;
    }
  }

  &-last .dashboard-stats__block--total-rides:first-child {
    h2 {
      margin: 0 0 5px;
    }

    small {
      margin: 0 0 10px;
      text-align: center;
    }

    .btn {
      font-size: em(10);
      padding: 8px 12px 7px 11px;
    }
  }
}
