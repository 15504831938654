.dashboard-stats__leader__block {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  padding: 1%;

  h2{
    margin-bottom: 10px;
  }

  h4{
    margin: 0;
    text-transform: none;
  }

  &__icon{
    position: relative;
    float: left;
    display: inline-block;
    height: 90px;
    width: 90px;
    margin-right: 40px;
    background: $white;
    border-radius: 50%;
    font-size: em(60);
    color: $bcLightPurple;
    vertical-align: middle;

    &:before {
      @include centerer();
    }
  }
}