.dashboard-home__statistics__card--route{
    background: $bcPurple;
    border-color: $bcPurple;
    color: $white;

    h2,
    h4{
      color: $white;
    }

    [class^="bc-"],
    [class*=" bc-"] {
      color: $white;
      right: 15px;
    }
  }
