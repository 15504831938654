.riders-list {
  .profile-container {
    display: inline-block;
  }
}

.ride-leaders {
  p {
    display: inline-block;
    padding: 0  $h-space;
  }

  .profile-container + p {
    @include profileContainerSibling($profile-image-80-offset);
  }


  .btn {
    margin-top: $line-height-half;
    margin-bottom: $line-height-half;
  }
}

.ride-leaders__leader,
.ride-leaders__assistant {
  padding: $line-height-computed $h-space 0;

}

.ride-leaders__leader {
  background-color: $bcLightGrey;
  float: none;

  .profile-container + p,
  .btn {
    @include profileContainerSibling($profile-image-80-offset * 1.5);
  }

  .modal .btn {
    top: auto;
    margin: 0;

  }
}

.riders-list__user-list {
  /*margin-top: $line-height-computed;*/
}