.styled-select{
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  zoom: 1;
  *display: inline;

  background-color: $white;

  box-shadow:none!important;
  border:2px solid $bcLightGrey;
  border-radius: $input-border-radius;

  .has-error &{
    &:after{
      color: $bcRed;
    }
  }

  &:after{
    content: "\6b";
    font-family: bc-icons;
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 1;
    width: 30px;
    height:100%;
    line-height: 2.6em;
    text-align:center;
  }

  select {
    display: block;
    position: relative;
    z-index: 2;
    appearance: none;
    background: transparent;
    border-width: 3px;
    width:100%;
    border:0;
    padding:6px 30px 6px 12px;
    height:40px;
  }
}