.page-header {

  margin-top:0;
  margin-bottom:0;
  padding-bottom:0;
  text-align:center;
  padding-top:1px;
  border-bottom: none;

  &.no-border {
    border:0;
  }

  h1, h2 {
    padding-bottom:15px;
  }
}