.form-control{
  border-width: $borderWidth;
}

form {

  @include clearfix();

  textarea,
  select,
  input[type=text],
  input[type=email],
  input[type=search],
  input[type=tel],
  input[type=url],
  input[type=password],
  input[type=color],
  input[type=time],
  input[type=week],
  input[type=month],
  input[type=datetime],
  input[type=datetime-local],
  input[type=date] {
    border-width: $borderWidth;
  }
}

.row-block .input-group {
  max-width:400px;
  margin:0 auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}