.dashboard-stats__block--total-rides {
  h3 {
    color: $bcLightPurple;
  }

  &.purple {
    color: #fff;
    border: none;
    background-color: $bcLightPurple;

    h2 {
      color: #fff;
      font-size: em(23);
      margin: 25px 0 0;
    }

    > h2 {
      margin: 12px 0 25px;
    }

    P {
      margin: 0;
    }
  }
}
