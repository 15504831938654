
.footer__newslestter {

  margin-bottom: 40px;

  p {
    margin-top: $padding-small-vertical;
    font-size: em(16);
    text-align: center;

    margin-bottom: 0;
    padding-bottom: 0;
  }

  form {
    padding: 0;
    text-align: center;
  }

  .form-control {
    width: 150px;
    box-shadow: none;
    border: 0;
  }

  .btn,
  .form-group{
    display: inline-block;
    font-size: em(14);
  }

  @media (min-width: $screen-sm-min) {

    form{
      text-align: right;
    }

    .form-control{
      width: auto;
    }

    .btn {
      margin-left: $padding-large-horizontal;
    }

    p {
      font-size: em(18);
      text-align: left;
    }
  }
}