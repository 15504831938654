
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"]{
  position: absolute;
  margin:0;
  left:0px;
  top:6px;
  border:0;
  box-shadow: none;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"]{
  top:6px;
}


.radio label, .checkbox label {
  padding-left:0;
}


.radio,
.radio-inline {
  padding:0px 10px 0px 30px;
}

.checkbox-inline,
.radio-inline {
  input[type="text"] {
    width:60px;
    display:inline-block;
    padding:0 4px;
    height:auto;
  }
}


.checkbox,
.checkbox-inline {
  padding:0px 10px 0px 26px;
  span.checkbox-align {
    position: relative;
    top: 2px;
  }
}


input[type="text"],
input[type="email"],
input[type="password"],
//input[type="time"],
textarea,
select {

  box-shadow:none!important;
  border-width:$borderWidth;

  &:active,
  &:focus {
    border-color: $bcPurple!important;
    box-shadow: none !important;
  }

}

select.smart-search-select {
  height:44px!important;
  max-height:44px!important;
  margin-bottom:15px;
}

.smart-search-button {
  margin-top:3px;
}

.file-input-wrapper {
  position: relative;
  border:2px solid $bcLightGrey;
  border-radius:$input-border-radius;
  height:44px;
  //margin-bottom:20px;
  padding:6px 12px;

  .btn {
    position: absolute;
    top:0;
    right:0;
    text-transform: uppercase;
    font-weight:bold;
    font-size:em(20);
    padding:4px 10px 6px;
    border-radius:0;
    box-shadow:none;

    &:hover,
    &:focus {
      color:$bcPurple;
    }

  }
}

fieldset {

  &:last-child {
    margin-bottom:0;
  }

}

fieldset {
  margin-top:0px;
  margin-bottom:15px;
  padding-top:1px;

  legend {
    font-size:em(18);
    border-bottom:0;
    margin-bottom:10px;
    font-weight:bold;
  }

}
label {

  position: relative;

  .checkbox &,
  .radio &,
  .radio-inline &,
  .checkbox-inline & {
    position: static;
  }

  &.label--required {

    &:before {

      content:'*';
      position: absolute;
      top:5px;
      left:-16px;
      color:$bcRed;
      font-size:em(25);
      line-height:1;

      @media (min-width: $screen-sm-min) {
        left:-12pxx;
      }
    }

  }
}

