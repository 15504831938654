/*
sourced from https://gist.githubusercontent.com/orizens/4994397/raw/b8498b860fa0986223956c88780ec53932624df4/bootstrap-dropdown-menu-sliding.css
*/
.dropdown-menu.dropdown-transition {
  transform-origin: top;
  animation-fill-mode: forwards;
  transform: scale(1, 0);
  display: block;
  transition: all $transition ease-out;

  .open & {
    transform: scale(1, 1);
  }

  .dropdown-menu {
    transform-origin: bottom;
  }
}