.hero-block {
  position: relative;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;

  /* Force colors for hero as always grey/black */
  color: $black !important;
  h1, h2, h3, h4, h5, h6 {
    color: $black !important;
  }

  &.no-transparency:before {
    background: transparent !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($bcLightGrey, .5)
  }

  &__head-image {
    img {
      height: 80px;
      margin-bottom: 25px;
      @media (min-width: $screen-sm-min) {
        height: 130px;
        margin-bottom: 45px !important;
      }
    }
  }

  &.hero-lg {

    padding: 100px 0 !important;

    @media (min-width: $screen-sm-min) {
      padding: 170px 0 !important;
    }
  }

  &.hero-md {

    padding: 70px 0 !important;

    @media (min-width: $screen-sm-min) {
      padding: 115px 0 !important;
    }

  }

  &.hero-sm {

    padding: 35px 0 !important;

    @media (min-width: $screen-sm-min) {
      padding: 80px 0 !important;
    }
  }

  &.hero-xs {

    padding: 20px 0 !important;

    @media (min-width: $screen-sm-min) {
      padding: 40px 0 !important;
    }
  }

  &.hero-logo {
    padding-top: 20px !important;

    @media (min-width: $screen-sm-min) {
      padding-top: 40px !important;
    }
  }

  h1 {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: $padding-large-vertical;
  }

  p {
    font-size: em(18);
    font-family: $font-family-sans-serif-regular;
  }

  * {
    position: relative;
    z-index: 2;
  }

  .btn-circle {
    &.btn-circle-hero {
      margin-top: 30px;
      background-color: $bcRed !important;
      border-color: $bcRed !important;
      color: $white !important;

      &:hover,
      &:focus {
        background-color: $white !important;
        border-color: $bcRed !important;
        color: $bcRed !important;
      }
    }
  }

  .container-fluid {
    position: relative;
    top: 5px;
  }

  .video-container {
    position: absolute;
    top: 0;
    width: 100%;
    overflow: hidden;
    height: 100%;
    video {
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      background-size: cover;
      background-position: center;
    }
  }
}

.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}

.hero-block-image-only {
  img {
    max-width: 1200px;
    margin: auto;
  }
}