.ride-schedule__item__buttons {
  //extend .col-xs-12;
  //extend .col-ms-4;
  margin-top: 10px;
  padding: 0;
  float: right;

  @media (min-width: $screen-md) {
    margin: 0;
  }

  .btn {
    //extend .col-sm-6;
    width: 100%;
    margin-bottom: 10px;
    color: $white;

    @media (min-width: $screen-md) {
      width: 48%;
      margin: 0 1%;

      &:nth-child(3) {
        margin-top: 10px;
      }
    }
  }
}