.icon-circle {
  position: relative;
  display: inline-block;
  height: 38px;
  width: 38px;
  background: $bcLightPurple;
  border-radius: 50%;
  font-size: em(22);
  color: $white;
  vertical-align: middle;

  &:before {
    @include centerer();
  }

  &--large {
    height: 50px;
    width: 50px;
    font-size: em(26);
  }
}