.conversations-list {

  .list-group-item {
    position: relative;
    padding: 10px;

    &.active-conversation {
      background: $bcLightPurple;

      a {
        color: $white;
        font-weight: bold;
      }

      .conversations-list__icon {
        color: $white;
      }

    }
  }

  &__link {
    float: right;
    width: 85%;
  }

  &__leave {
    float: right;
    color: $brand-danger;
  }

  &__icon {
    float: left;
    width: 5%;
    font-size: em(30);
    vertical-align: middle;
  }

  .pagination {
    display:none;
  }

}