// Base
@import "base/_bc-icons.scss";
@import "base/_colours.scss";
@import "base/_mixins.scss";
@import "base/_responsive.scss";
@import "base/_typograhpy.scss";
@import "base/_variables.scss";

// Frameworks, plugins
@import "vendor/bootstrap";
@import "vendor/bootstrap-slider";
@import "vendor/bootstrap-switch";
@import "vendor/daterangepicker";
@import "vendor/fileInput";
@import "vendor/select2";
@import "vendor/bootstrap-clockpicker";

// Global components
@import "components/blogs/_blogs.scss";
@import "components/global/_author-horizontal.scss";
@import "components/global/_block-layout.scss";
@import "components/global/_carousel.scss";
@import "components/global/_categories.scss";
@import "components/global/_close.scss";
@import "components/global/_default-messages.scss";
@import "components/global/_default.scss";
@import "components/global/_details-container.scss";
@import "components/global/_details-starting-block.scss";
@import "components/global/_google-map.scss";
@import "components/global/_headings.scss";
@import "components/global/_icon-circle.scss";
@import "components/global/_icon-colours.scss";
@import "components/global/_images.scss";
@import "components/global/_modals.scss";
@import "components/global/_short-description.scss";
@import "components/global/_star-container.scss";
@import "components/global/_table-responsive.scss";
@import "components/global/_text.scss";
@import "components/global/_user-list.scss";
@import "components/global/_video-responsive.scss";
@import "components/global/_white-card.scss";
@import "components/global/_wrappers.scss";
@import "components/global/blocks/_checklist.scss";
@import "components/global/blocks/_ctalist.scss";
@import "components/global/blocks/_featured.scss";
@import "components/global/blocks/_hero-block.scss";
@import "components/global/blocks/_image-block--video.scss";
@import "components/global/blocks/_image-block.scss";
@import "components/global/blocks/_image-gallery.scss";
@import "components/global/blocks/_infoblocks.scss";
@import "components/global/blocks/_news-block.scss";
@import "components/global/blocks/_partners.scss";
@import "components/global/blocks/_quick_booking.scss";
@import "components/global/blocks/_social-block.scss";
@import "components/global/blocks/_video_block.scss";
@import "components/global/buddies/_remove-buddy-confirmation.scss";
@import "components/global/collapse-panels/_task-panel.scss";
@import "components/global/comments-ratings/_comments.scss";
@import "components/global/comments-ratings/_star-rating.scss";
@import "components/global/cta/_create-ride-cta.scss";
@import "components/global/dashboard/_dashboard-navigation-actions.scss";
@import "components/global/dashboard/_dashboard-navigation-mobile.scss";
@import "components/global/dashboard/_dashboard-navigation.scss";
@import "components/global/dashboard/buddies/_buddy-request.scss";
@import "components/global/dashboard/buddies/_dashboard-buddy-search.scss";
@import "components/global/dashboard/groups/_group-listing__call-o-actions.scss";
@import "components/global/dashboard/groups/_group-listing__pagination.scss";
@import "components/global/dashboard/groups/create/_checkbox.scss";
@import "components/global/dashboard/groups/create/_groups-members.scss";
@import "components/global/dashboard/groups/create/_groups-thumbnail.scss";
@import "components/global/dashboard/home/_dashboard-home__ctas.scss";
@import "components/global/dashboard/home/_dashboard-home__statistics__card.scss";
@import "components/global/dashboard/home/_dashboard-home__statistics_rides.scss";
@import "components/global/dashboard/home/_dashboard-home__statistics.scss";
@import "components/global/dashboard/home/_ride-search.scss";
@import "components/global/dashboard/home/call-to-actions/_create-ride-cta.scss";
@import "components/global/dashboard/home/call-to-actions/_grey-cta.scss";
@import "components/global/dashboard/home/call-to-actions/_groups-cta.scss";
@import "components/global/dashboard/home/call-to-actions/_notifications-cta.scss";
@import "components/global/dashboard/home/call-to-actions/_routes-cta.scss";
@import "components/global/dashboard/home/ride-schedule/_ride-schedule__item__buttons.scss";
@import "components/global/dashboard/home/ride-schedule/_ride-schedule__item__date.scss";
@import "components/global/dashboard/home/ride-schedule/_ride-schedule__item__details.scss";
@import "components/global/dashboard/home/ride-schedule/_ride-schedule__item.scss";
@import "components/global/dashboard/home/ride-schedule/_ride-schedule__no-rides.scss";
@import "components/global/dashboard/home/ride-schedule/_ride-schedule__quick-details.scss";
@import "components/global/dashboard/home/ride-schedule/_ride-schedule__reviews.scss";
@import "components/global/dashboard/home/ride-schedule/_ride-schedule-controls.scss";
@import "components/global/dashboard/home/ride-schedule/_ride-schedule-filter.scss";
@import "components/global/dashboard/messages/_messages-add-buddies.scss";
@import "components/global/dashboard/profile/_panels.scss";
@import "components/global/dashboard/profile/edit/_edit-profile__cycling-preferences.scss";
@import "components/global/dashboard/profile/view/_profile-dashboard.scss";
@import "components/global/dashboard/ride-admin/_leader-evaluation-form.scss";
@import "components/global/dashboard/ride-admin/_ride-schedule__item__date.scss";
@import "components/global/dashboard/ride-admin/_risk-assessment.scss";
@import "components/global/dashboard/rides/create/_checkbox.scss";
@import "components/global/dashboard/rides/create/_panels.scss";
@import "components/global/dashboard/rides/create/series/_series-frequency-options.scss";
@import "components/global/dashboard/stats/stats-blocks/_dashboard-stats__block--total-miles.scss";
@import "components/global/dashboard/stats/stats-blocks/_dashboard-stats__block--total-participants.scss";
@import "components/global/dashboard/stats/stats-blocks/_dashboard-stats__block--total-rides.scss";
@import "components/global/dashboard/stats/stats-blocks/_dashboard-stats__block.scss";
@import "components/global/dashboard/stats/stats-blocks/_dashboard-stats__leader__block.scss";
@import "components/global/dashboard/stats/stats-blocks/_dashboard-stats__leader.scss";
@import "components/global/dashboard/stats/stats-cards/_dashboard-home__statistics__card--buddy.scss";
@import "components/global/dashboard/stats/stats-cards/_dashboard-home__statistics__card--calories.scss";
@import "components/global/dashboard/stats/stats-cards/_dashboard-home__statistics__card--rides-created.scss";
@import "components/global/dashboard/stats/stats-cards/_dashboard-home__statistics__card--rides-led.scss";
@import "components/global/dashboard/stats/stats-cards/_dashboard-home__statistics__card--rides-next.scss";
@import "components/global/dashboard/stats/stats-cards/_dashboard-home__statistics__card--route.scss";
@import "components/global/footer/_footer__contact.scss";
@import "components/global/footer/_footer__links.scss";
@import "components/global/footer/_footer__newsletter.scss";
@import "components/global/forms/_address.scss";
@import "components/global/forms/_buttons.scss";
@import "components/global/forms/_checkbox-inline.scss";
@import "components/global/forms/_clock-picker.scss";
@import "components/global/forms/_control-group.scss";
@import "components/global/forms/_date.scss";
@import "components/global/forms/_dropdown-transition.scss";
@import "components/global/forms/_dropdown.scss";
@import "components/global/forms/_file.scss";
@import "components/global/forms/_form-group.scss";
@import "components/global/forms/_form-validation.scss";
@import "components/global/forms/_forms.scss";
@import "components/global/forms/_image-upload.scss";
@import "components/global/forms/_input-group-search.scss";
@import "components/global/forms/_input-group.scss";
@import "components/global/forms/_inputs.scss";
@import "components/global/forms/_label.scss";
@import "components/global/forms/_radio-image.scss";
@import "components/global/forms/_search-location.scss";
@import "components/global/forms/_styled-select.scss";
@import "components/global/forms/_ui-date-range-picker.scss";
@import "components/global/forms/_ui-slider.scss";
@import "components/global/header/_header__account-mobile.scss";
@import "components/global/header/_header__account.scss";
@import "components/global/header/_header__login.scss";
@import "components/global/header/_header__search--product.scss";
@import "components/global/header/_header__search.scss";
@import "components/global/header/_navbar-header.scss";
@import "components/global/images/_global.scss";
@import "components/global/images/_logo.scss";
@import "components/global/images/_photo-gallery.scss";
@import "components/global/images/_profile-picture.scss";
@import "components/global/links/_breadcrumb.scss";
@import "components/global/links/_links.scss";
@import "components/global/lists/_listing-output.scss";
@import "components/global/lists/_styled-list.scss";
@import "components/global/messages/_conversation-messages.scss";
@import "components/global/messages/_conversations-list.scss";
@import "components/global/messages/_message-block.scss";
@import "components/global/messages/_messages-add-buddies.scss";
@import "components/global/messages/_messages-list.scss";
@import "components/global/messages/_send-message.scss";
@import "components/global/navigation/_dropdown.scss";
@import "components/global/navigation/_links.scss";
@import "components/global/navigation/_menu-button.scss";
@import "components/global/navigation/_navbar--product.scss";
@import "components/global/navigation/_navbar-toggle.scss";
@import "components/global/news/_featured-news.scss";
@import "components/global/routes/_route-listing.scss";
@import "components/global/search/_featured-search.scss";
@import "components/global/search/_smart-search.scss";
@import "components/global/sharing/_invite-users.scss";
@import "components/global/sharing/_social-media-toolbar.scss";
@import "components/global/site-tools/_alerts.scss";
@import "components/global/site-tools/_back-to-top.scss";
@import "components/global/site-tools/_loader.scss";
@import "components/global/site-tools/_location-search.scss";
@import "components/global/site-tools/_page-header.scss";
@import "components/global/site-tools/_panel-title.scss";
@import "components/global/summary-block/_notification-block.scss";
@import "components/global/summary-block/_summary-block.scss";
@import "components/global/tables/_table-forms.scss";
@import "components/global/tables/_table.scss";
@import "components/global/tabs/_tabs.scss";
@import "components/groups/_group-details.scss";
@import "components/rides/_booked.scss";
@import "components/rides/_cancelled-actions.scss";
@import "components/rides/_level-tag.scss";
@import "components/rides/_ride-series.scss";
@import "components/rides/_riders-list.scss";
@import "components/rides/listing-filter-form/_listings__filter-form__more.scss";
@import "components/rides/listing-filter-form/_listings__filter-form.scss";
@import "components/rides/listing-filter-form/_listings__filter-inputs.scss";
@import "components/rides/listing-filter-form/_listings.scss";
@import "components/rides/ride-listing/_ride-listing.scss";
@import "components/routes/_featured.scss";
@import "components/users/_profile-details.scss";

// Layout elements
@import "layouts/_container.scss";
@import "layouts/_grid.scss";
@import "layouts/elements/_footer.scss";
@import "layouts/elements/_header.scss";

// Product templates
@import "products/_breeze.scss";
@import "products/_local-rides.scss";
@import "products/_ride-social.scss";

// Pages
//@import "pages/**/*.scss";

// Helpers, overides
@import "helpers/_helpers.scss";

.level-tag {
  position: relative;
  top: 1px;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 10px;
  color: #ffffff; }
.level-tag--mtb {
  background: #744d25; }
.bike {
  background: #744d25; }