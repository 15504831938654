.dashboard-buddy-search{
  margin-bottom: 20px;
  text-align: center;
  h4{
    display: inline-block;
    text-transform: none;

    @media (min-width: $screen-sm) {
    margin-right: 20px;
    }
  }

  form{
    display: inline-block;
    width: 300px;
    vertical-align: middle;
  }
}