.invite-suggested-users {

  .suggested-user {
    display:table;

    button {
      display:table-cell;
      margin-bottom: $line-height-half;
    }

    button:nth-child(1) {
      max-width:30%;
      vertical-align: middle;
    }

    button:nth-child(2) {
      max-width:70%;
      vertical-align: middle;
      text-align:left;
    }

    .remove {
      display: none;
      position: absolute;
      left: 7px;
      top: 7px;
    }

    .text-button {
      position: relative;
      padding: 5px 10px 5px 30px;
      border-radius: $border-radius-base;
      color: $bcPurple;
    }

    &.suggested-selected {

      .text-button {
        background-color: $bcPurple;
        color: $white;
      }

      .remove {
        display: block;
        position: absolute;
      }
    }
  }
}

.invited-friend {

  margin-bottom:20px;

  > span {

    position: relative;
    display:inline-block;
    background-color: $bcPurple;
    color: $white;
    padding: 5px 10px 5px 30px;
    border-radius: $border-radius-base;

    .bc-glyphicon-remove {
      position: absolute;
      top:7px;
      left:6px;
      width:16px;
      height:18px;
      color:white;
    }
  }

}