$textLabelOffset: 25px;

.slider.slider-horizontal {
  display:block;
  width: 90%;
  margin: 0 auto;
}

.slider-selection {
  @extend %tick-slider-selection;
}

.js .slider--labels {
  padding-bottom: $textLabelOffset;
}

.js-tick-label {
  position: absolute;
  display: block;
  top: $textLabelOffset;
  text-align: center;
}

.ui-distance-slider .slider{
  margin-top: 20px;
}