.nav-pills {
  text-align:center;
  border-bottom: none;
  position: relative;
  z-index: 10;
  top: 20px;
}

.nav-pills > li {
  float:none;
  display:inline-block;



  > a {
    font-weight: 600;
    background-color: $white;
    border: solid $wrapper-border-width $bcLightGrey;
    line-height: 1;
    padding: 15px;

    .icon:before {
      font-size: 1.9em;
      line-height: 0;
      position: relative;
      top: 8px;
      margin-right: $icon-from-text-spacing;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $bcLightPurple;
      border: solid $wrapper-border-width $bcLightPurple;
      color: $white;
    }
  }
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: $bcLightPurple;
  border: solid $wrapper-border-width $bcLightPurple;
  margin-right: 0;
}

@include under-sm {
  .nav-pills {
    text-align: left;

    > li {
      float: left;
      display: block;
      width: 100%;
    }

    > li + li {
      margin-left: 0;
    }

    > li > a {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    > li + li a,
    > li + li a:hover,
    > li + li a:focus,
    > li + li.active > a,
    > li + li.active > a:hover,
    > li + li.active > a:focus {
      border-top: none;
      border-radius: 0;
    }
  }
}





.tab-content {
  @include borderWrapper();
  padding-top: $line-height-computed * 2;
  border-radius: $border-radius-base;
}

.tab-pane {
  h2, h3 {
    text-align: center;
  }
}