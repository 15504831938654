
.header__search--product {
  &.header__search{
    padding: $padding-large-vertical 0;
  }
  .header__search__form {
    display: none;
    width: 90%;
    margin: 0 auto;
    &.collapse.in {
      display: block;
    }
  }

  .input-group i {
    color: $black;
  }

  @media (min-width: $screen-sm-min) {
    .header__search__form {
      display: block;
      float: right;
      width: 30%;
      min-width: 400px;
    }

    &.header__search{
      padding: $padding-large-vertical * 2 0;
    }
  }

  @media (min-width: $screen-md-min) {
    .header__search__form {
      padding: 0;
    }

    .product-logo {
      float: left;
    }
  }
}