@charset "UTF-8";

@font-face {
  font-family: "bc-icons";
  src:url("/fonts/bcIcons/bc-icons.eot");
  src:url("/fonts/bcIcons/bc-icons.eot?#iefix") format("embedded-opentype"),
  url("/fonts/bcIcons/bc-icons.woff") format("woff"),
  url("/fonts/bcIcons/bc-icons.ttf") format("truetype"),
  url("/fonts/bcIcons/bc-icons.svg#bc-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "bc-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="bc-"]:before,
[class*=" bc-"]:before {
  font-family: "bc-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bc-glyphicon-search:before {
  content: "\63";
}
.bc-glyphicon-user:before {
  content: "\64";
}
.bc-glyphicon-star:before {
  content: "\65";
}
.bc-glyphicon-minus:before {
  content: "\66";
}
.bc-glyphicon-remove:before {
  content: "\67";
}
.bc-glyphicon-chevron-left:before {
  content: "\68";
}
.bc-glyphicon-chevron-right:before {
  content: "\69";
}
.bc-glyphicon-chevron-up:before {
  content: "\6a";
}
.bc-glyphicon-chevron-down:before {
  content: "\6b";
}
.bc-fa-facebook:before {
  content: "\61";
}
.bc-fa-youtube-play:before {
  content: "\62";
}
.bc-fa-chevron-up:before {
  content: "\6c";
}
.bc-fa-chevron-down:before {
  content: "\6d";
}
.bc-fa-twitter:before {
  content: "\6e";
}
.bc-fa-instagram:before {
  content: "\6f";
}
.bc-fa-circle-o:before {
  content: "\70";
}
.bc-fa-plus:before {
  content: "\71";
}
.bc-fa-minus:before {
  content: "\72";
}
.bc-fa-calendar-o:before {
  content: "\73";
}
.bc-fa-chevron-left:before {
  content: "\74";
}
.bc-fa-chevron-right:before {
  content: "\75";
}
.bc-glyphicon-folder-open:before {
  content: "\76";
}
.bc-group:before {
  content: "\77";
}
.bc-achievements:before {
  content: "\78";
}
.bc-breeze-assistant:before {
  content: "\79";
}
.bc-edit:before {
  content: "\7a";
}
.bc-resources:before {
  content: "\41";
}
.bc-stats-star:before {
  content: "\42";
}
.bc-time:before {
  content: "\43";
  position: relative;
  top: 3px;
}
.bc-email:before {
  content: "\44";
}
.bc-expand:before {
  content: "\47";
}
.bc-ride-admin:before {
  content: "\48";
}
.bc-upload:before {
  content: "\49";
}
.bc-verified-route:before {
  content: "\4a";
}
.bc-full-star:before {
  content: "\4c";
}
.bc-buddy:before {
  content: "\4d";
}
.bc-video-play:before {
  content: "\4f";
}
.bc-calories:before {
  content: "\50";
}
.bc-checkmark:before {
  content: "\51";
}
.bc-half-star:before {
  content: "\52";
}
.bc-ride-series:before {
  content: "\53";
}
.bc-climb:before {
  content: "\54";
}
.bc-rocket:before {
  content: "\56";
}
.bc-close:before {
  content: "\57";
}
.bc-location:before {
  content: "\58";
  position: relative;
  top: 3px;
}
.bc-route:before {
  content: "\59";
}
.bc-co2:before {
  content: "\5a";
}
.bc-messages:before {
  content: "\30";
}
.bc-dash-home:before {
  content: "\32";
}
.bc-date:before {
  content: "\33";
}
.bc-download:before {
  content: "\34";
}
.bc-dash-settings:before {
  content: "\35";
}
.bc-needs-leader:before {
  content: "\36";
}
.bc-shield:before {
  content: "\37";
}
.bc-stats:before {
  content: "\38";
}
.bc-photos:before {
  content: "\23";
}
.bc-breeze-champion:before {
  content: "\46";
}
.bc-info:before {
  content: "\55";
}
.bc-ride-creator:before {
  content: "\4b";
}
.bc-ride-leader:before {
  content: "\4e";
}
.bc-fontawesome-webfont:before {
  content: "\39";
}
.bc-search:before {
  content: "\31";
}
.bc-locate:before {
  content: "\31";
}
.bc-exchange:before {
  content: "\21";
}
.bc-share:before {
  content: "\31";
}
.bc-profile:before {
  content: "\22";
}
.bc-profile.small-icon:before {
  position: relative;
  top: 2px;
}
.bc-fontawesome-webfont-1:before {
  content: "\31";
}
.bc-search:before {
  content: "\26";
}
.bc-notifications:before {
  content: "\24";
}
.bc-bell:before {
  content: "\25";
}
.bc-external-link:before {
  content: "\27";
}
.bc-measure:before {
  content: "\28";
}
.bc-ride:before {
  content: "\45";
  position: relative;
  top: 3px;
}
.bc-distance:before {
  content: "\29";
  position: relative;
  top: 3px;
}
.bc-check-square:before {
  content: "\2b";
}
