.ride-schedule__item__details {
  //extend .col-xs-12;
  //extend .col-sm-10;
  padding: 15px;
  border: solid 2px $bcLightGrey;
  border-radius: 0 0 $border-radius-base $border-radius-base;

  @media (min-width: $screen-sm) {
    min-height: 130px;
    border-top: solid 2px $bcLightGrey;
    border-radius: $border-radius-base;
  }

  &__main{
    //extend .col-ms-8;
  }

  .product-logo{
    float: left;
    margin-right: 20px;
  }

  &__reviews{
    @include clearfix();
    margin-top: 10px;
  }
}