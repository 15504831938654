$caption-padding: 5px;
$share-btn-width: 55px;

.image-gallery {

  .image-container {
    margin-bottom: $line-height-half;
  }

  .image-container:nth-child(3n + 1) {
    clear: left;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    .image-container:nth-child(3n + 1) {
      clear: none;
    }

    .image-container:nth-child(2n + 1) {
      clear: left;
    }
  }

  @media (max-width: $screen-xs-max) {
    .image-container {
      clear: both;
    }
  }

  figure {
    position: relative;
  }

  figcaption {
    display: block;
    position: absolute;
    bottom: 0;
    background-color: rgba($bcPurple, $imageOverlayOpacity);
    padding: $caption-padding $line-height-half;
    width: 100%;

    p {
      margin: 0;
      color: $white;
      position: relative;
      margin-right: $share-btn-width;
    }

    a {
      width: $share-btn-width;
      position: absolute;
      display: block;
      color: $white;
      top: $caption-padding;
      right: 0;
      font-weight: 600;
      font-size: $bold-font-size;
    }

  }

  @include under-sm {
    figcaption {
      position: relative;
      background-color: $bcPurple;
    }
  }

}