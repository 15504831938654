.checkbox-inline,
.checkbox-inline + .checkbox-inline,
.checkbox-inline + .radio-inline,
.radio-inline,
.radio-inline + .radio-inline,
.radio-inline + .checkbox-inline {
  margin-left: 0;
  margin-right: 10px;
}

.checkbox-inline:last-child,
.radio-inline:last-child {
  margin-right: 0;
}