// only overwriting styles for the latest select not select2--classic

.select2-container--default .select2-search--inline .select2-search__field {
  padding:4px 5px;
}

.select2-container--default {
  margin-bottom: $line-height-half;
}

.select2-container .select2-selection--single {
  height: $input-height-base;
}

.select2-dropdown {
  background-color: $white;
  border: $wrapper-border-width solid $bcLightGrey;
  border-radius: $border-radius-base;
}

.select2-container--default .select2-selection--single {
  background-color: $white;
  border: $wrapper-border-width solid $bcLightGrey;
  border-radius: $border-radius-base;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
}

.select2-container--default .select2-selection--multiple {
  background-color: $white;
  border: $wrapper-border-width solid $bcLightGrey;
  border-radius: $border-radius-base;
}


.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: $bcPurple;
  border-radius: $border-radius-base;
  color: $white;
  padding: 5px 7px;
}


.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: $wrapper-border-width solid $bcPurple;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: $wrapper-border-width solid $bcLightGrey;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $bcPurple;
  color: $white;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $bcPurple;
  color: $white;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: $white;
  margin-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: $white;
}

// following styles are for the results template generated by javascript in smartSearch.js
.select2-container--default [class^="col-"] {
  padding: 10px;
}

.select2-container--default .smart-search__text-wrapper {
  padding-top: 15px;
}

@include only-sm() {
  .select2-container--default .smart-search__text-wrapper {
    padding-top: 30px;
  }
}

@include under-sm() {
  .smart-search__image-wrapper {
    display: none;
  }
}

.select2-container--default .select2-selection--single{

  .select2-selection__rendered,
  .select2-selection__arrow{
    height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}

// Hiding the arrow confuses the user
/*.select2-selection__arrow {
  display: none;
}*/
.select2-selection__arrow:after {
  content: "\6b";
  font-family: bc-icons;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 1;
  width: 30px;
  height: 100%;
  line-height: 2.6em;
  text-align: center;

}

.select2-selection__arrow b {
  display: none;
}

// toggle arrow direction on open select
/*.select2-container--open .select2-selection__arrow:after {
  content: "\6a";
}*/