
.google-map-container-full-size {

  margin-top:$padding-large-vertical*2;

  background-image: url(/images/loader.gif);
  background-repeat: no-repeat;
  background-position: center;
}

.google-map-container {

  margin-top:$padding-large-vertical*2;
  height:150px;

  background-image: url(/images/loader.gif);
  background-repeat: no-repeat;
  background-position: center;
}

.google-map-container-pdf {
  margin:30px 0;
  height:0;
  padding-bottom:56.25%;
}

// google maps and charts on details pages
.details--google-map {
  width: 100%;
  min-height: 450px;
  /*
  Hide this by default, until we click .process-map a:
  */
  display: none;


  @include under-sm {
    min-height: 350px;
  }
}

.details--elevation-chart-wrapper {
  width: 100%;
  min-height: 300px;

  @include under-sm {
    min-height: 200px;
  }
}

div.details--elevation-chart-parent {
  /*
  Hide this by default, until we click .process-map a:
  */
  display: none;
}

div.process-map {
  display: block; height: 450px; width:100%; background: #fff url('/images/process-map.jpg') no-repeat; text-align: center; padding-top: 200px; cursor: pointer;
  @include under-sm {
    height: 350px;
    padding-top: 100px;
  }
}