
h1,
h2,
h3,
h4,
h5,
h6 {

  color: $bcPurple;
  text-transform: uppercase;

    &.alt-heading {
    color: $bcPurple;
  }
}


h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: $font-family-sans-serif;
}