.booked {
  @include borderWrapper();
  border-color: $bcSuccess;

  .h2, .sub-heading, .title-icon {
    color: $bcSuccess;
  }

  .title-icon {
    font-size: 3em;
    position: relative;
    top: 13px;
  }

  .h2, .social-media-toolbar {
    display: inline-block;
  }

  .h2, .title-icon {
    margin-right: $h-space-double;
  }

  .share-btn {
    background-color: $bcSuccess;

    &:hover,
    &:active,
    &:visited,
    &:focus {
      border-color: $bcSuccess;
      background-color: $white;
      color: $bcSuccess;
    }
  }
}

@include under-md() {
  .booked {
    .h2 {
      margin-right: 0;
    }

    .social-media-toolbar {
      width: 100%;
      margin-top: $line-height-half;
    }
  }
}

@include under-md() {

}