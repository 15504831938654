
.details-container + .details-container {
  .details {
    margin-top:0;
  }
}

.details-container {

  //border:1px solid black;
  margin-bottom:15px;
  overflow:hidden;

  .profile-picture {
    //border:1px solid orangered;
    float:left;
    margin-right:15px;
    border-radius:50%;
  }

  .details {
    //border:1px solid red;
    overflow:hidden;

    @media (min-width: $screen-sm-min) {
      margin-top:15px;
    }


    .fullname {

      margin-bottom:10px;

      @media (min-width: $screen-sm-min) {
        //border:1px solid purple;
        float:left;
        margin-bottom:0px;
        margin-right:15px;
      }
    }

    .published {

      margin-bottom:10px;

      @media (min-width: $screen-sm-min) {
        //border:1px solid purple;
        float:left;
        margin-bottom:0px;
        margin-right:15px;
      }
    }

    .categories {

      margin:0;

      @media (min-width: $screen-sm-min) {
        //border:1px solid purple;
        float:left;
      }

      .label {
        display: inline-block;
        padding:5px 10px;
        background-color: $bcLightGrey;
        color:$black;
        font-weight:normal;
      }
    }


  }
}