.dashboard-home__statistics__card--rides-created{
  background: $turquoise;
  border-color: $turquoise;

  h2,
  h4{
    color: $white;
  }

  [class^="bc-"],
  [class*=" bc-"] {
    color: $white;
    right: 10px;
  }
}