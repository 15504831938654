
.header__account {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 33px;
  right: 80px;
  z-index:1000;

  @media (min-width: $screen-sm-min) {
    position: relative;
    top:38px;
    right:10px;
    transform:none;
  }

  //login in / join buttons
  a:not(.btn-account) {
    margin-left:10px;


    //style as text links up "sm" size
    @media (max-width: $screen-sm-min) {
      margin-left:0;
      background-color:transparent;
      border:0;
      border-radius:0;
      color:$bcPurple;
      border-right:1px solid $bcPurple;
      box-shadow:none;
      padding:0px 7px;

      &:last-child {
        border:0;
      }
    }

  }

  //logged in profile button
  .btn-account {

    position: relative;
    top:-12px;
    padding:18px 12px 18px 65px;
    position: relative;
    border:0;
    box-shadow:none;

    @media (min-width: $screen-sm-min) {
      padding-left:70px;
    }

    &:hover,
    &:focus {
      background-color:none!important;
      color:$bcPurple;

      span.profile-icon {
        background-color: $bcPurple;
      }
    }

    span.profile-icon {
      position: absolute;
      top: 5px;
      left: 20px;
      background-color: $white;
      border-radius: 100%;
      width: 44px;
      height: 44px;
      overflow:hidden;
      border:3px solid transparent;

      &:before {
        font-size: em(28);
        color: white;
      }
    }

    span.user-notifications-count {
      z-index:500;
      position: absolute;
      top:5px;
      left:5px;
      width:26px;
      height:25px;
      background-color:$bcRed;
      color:$white;
      padding:3px;
      border-radius:100%;
      overflow: hidden;
      span {
        font-size:em(20);
        font-weight:900;
        &:before {
          position: relative;
          top:1px;
        }
      }
    }

    i {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
      margin-top: -1px;
    }

    i:before {
      content: "\6c";
    }

    &.collapsed {
      i:before {
        content: "\6d";
      }
    }

  }//btn-account

}//header-account