
.summary-block + section {
  margin-top:30px;
}

//
//SUMMARY BLOCK HALF (Side by side profiles)
//
.summary-block-half {

  @include clearfix();

  >li {

    padding-bottom:0;

    .summary-block {
      margin-bottom:20px;

      .btn {
        min-width:auto;
        padding-left:10px;
        padding-right:10px;
      }
    }

  }
}



//
//SUMMARY BLOCK
//
.summary-block {

  @include clearfix();
  @include borderWrapper();
  padding:10px 0;

  &.notification--seen .image-container .icon-circle {
    background: #dff0d8; /* Bootstrap success color */
    color: #3c763d; /* Bootstrap success color */
  }
  &.notification--requires-action .image-container .icon-circle {
    background: #f2dede; /* Bootstrap danger color */
    color: #a94442;
  }

  &.featured-ride {
    border-width: 5px;
  }

  h3 {
    margin-top: 15px;
    margin-right: 10px;
    display: inline-block;
    text-transform: none;
    text-align:left!important;

    span {
      display: block;
    }
  }

  [class^="col-"] {
    padding-bottom: 0px;
  }

  a, .icon {
    //@extend .default-text-colour;
  }

  .women-only {
    display:inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    position: relative;
    top: -2px;
    padding: 4px 8px;
    border-radius: $border-radius-small;
    color: $white;
  }

  .featured-ride-box {
    display:inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    position: relative;
    top: -2px;
    padding: 4px 8px;
    border-radius: $border-radius-small;
    color: $black;
    background-color: $bcLightGrey;
  }

  //
  //CONTENT WRAPPER
  //
  .content-wrapper {
    @media (min-width: $screen-md-min) {
      margin-left:-10px;
    }
  }


  //
  //CONTENT CONTAINER
  //
  .content-container {
    @include clearfix();
    //margin-left:-10px;
    padding-bottom: $padding-large-vertical * 2;

    @media (min-width: $screen-md-min) {
      padding-bottom:0;
    }

    //details container is used in the summary block, but I made it into
    //it's own partial as it's used elsewhere

  }


  //
  //BUTTON CONTAINER
  //
  .button-container {

    //margin-left:-10px;
    text-align:center;

    @media (min-width: $screen-md-min) {
      margin-top: 15px;
      position: relative;
      margin-bottom: 15px;

      .details & {
        /*margin-top: 75px;*/
        right: 0;
      }


      &.h-t-margin--zero {
        margin-top: 0;
      }
      &.h-t-margin--line-height-quarter {
        margin-top: $line-height-quarter;
      }

      label {
        text-align: left;
      }
    }




    .btn {
      margin-bottom: $padding-large-vertical;
      margin-right: $padding-large-vertical;
      min-width:100%;


      @media (min-width: $screen-ms-min) {
        min-width:210px;
        padding-left:0;
        padding-right:0;
      }

      @media (min-width: $screen-md-min) {
        margin-right: 0;
      }
    }//btn

    .space-availability {
      text-align: center;
      .bc-fontawesome-webfont {
        font-size:em(36);
      }
    }

  }//button container

  //
  //BUTTON CONTAINER
  //
  .product-container {
    @include clearfix();
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: $hr-border;
    padding: $padding-large-vertical 0 $padding-large-vertical 0;
  }


  //VERY specific rule to reduce the button width
  //when it's in an article AND its in a 9/3 split page

  .col-lg-9 & {
    .button-container .btn {
      min-width:auto;
      padding:6px 12px;
    }
  }

  .ride-schedule__item & {
    .button-container .btn {
      min-width:170px;
    }
  }

  .icon__large {
    padding-right: 5px;
    font-size: em(25);
    vertical-align: middle;
  }

  .list-container {
    width: 50%;
    float: left;

    @include clearfix();

    li {
      .icon__on-after {
        padding-right: 10px;
        font-size: em(25);
        vertical-align: middle;
      }

      padding-bottom: $padding-large-vertical;

      @media (min-width: $screen-md-min) {
        display: block;
      }

      &:last-child {
        padding-bottom: 0;
      }

      li {
        border: 0 !important;
        padding: 0 !important;
        width: 100%;
      }
    }
  }//list-container

  .level-container {
    width: 50%;
    float: left;
  }

  .alert{

    @media (min-width: $screen-ms-min) and (max-width: $screen-md-min) {
      margin-bottom: $line-height-half;
    }

  } // alert


  //
  //ICONS
  //
  .property {
    margin-top: 6px;
    margin-bottom: $line-height-half;
    position: relative;

    &:last-child {
      margin-bottom:0;
    }
  }

  .location,
  .elevation {
    padding-left: 27px;

    i {
      position: absolute;
      left: 0px;
      top: 0px;
      width:23px;
      height:23px;
      font-size: 23px;
      //border:1px solid black;

      &:before {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
    }
  }

  .rating {
    .count {
      font-size: 12px;
      position: relative;
      top: -3px;
      left: 3px;
    }

    .details &  {
      margin-top: $line-height-quarter;
    }
  }

  .assessed {
    padding-left: 27px;
    i {
      position: absolute;
      left: 0px;
      top: 0px;
      width:20px;
      height:20px;
      background-color: $bcAssessedGreen;
      border-radius: 50%;
      color: white;
      font-size:14px;

      &:before {
        position: absolute;
        top:3px;
        left:3px;
      }

    }
  }

  .details & .summary-block__login-container {
    margin-top: $line-height-computed;
  }
  
  ul strong, ul b {
    display: block;
  }

  .profile-link {
    display: inline-block;
    margin-left: $h-space;
    margin-bottom: $line-height-half;
  }

  .updated-by {
    margin: 8px 0 0 0;
  }

  .completed-by {
    margin: 4px 0 0 0;
  }
} // end summary




//
//LOGIN CONTAINER
//
.login-container {

  .panel {
    border:0;
    box-shadow:none;
    margin-bottom:0;
  }

  .panel-heading {
    background:none;
    padding:0;
    font-size:em(22);
    border:0;
  }

  .panel-body {
    padding-left:0;
    padding-right:0;
  }

  //btn spacing
  .btn[type="submit"] {
    margin-right:10px;
  }

  .form-group > .label--required {
    padding-left: 20px;

    &:before {
      left: 5px;
    }
  }

  .checkbox > .label--required {
    padding-left: 20px;
    
    &:before {
      left: 25px;
    }
  }


  .button-container {
    text-align:center;
    @media (min-width: $screen-ms-min) {
      text-align:left;
    }

  }

  .dont-have-account {
    display:block;
    //margin-top:30px;
    text-align:center;
    margin-right:20px;

    @media (min-width: $screen-ms-min) {
      display:inline-block;
      margin-top:0;
    }
  }//dont have account

}//login container