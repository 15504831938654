.recent-ride-summary {
  background-color: $bcVeryLightGrey;
  padding: $line-height-half $h-space;

  h3 {
    text-transform: none;
    margin-top: 0;
  }

  a {
    display: block;
    margin-bottom: $line-height-half;
    font-weight: 600;
  }
}