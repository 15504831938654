.image-upload {

  .media-image {
    margin-bottom: 20px;
  }
  img {
    display: block;
    width: 165px;
    margin: 0 auto;
  }
  img.profile-picture {
    border-radius: 50%;
  }
  &__actions {
    text-align: center;

    .alert {
      display: block;
      width: 200px;
      margin: 0 auto;
      margin-top: 20px;
      text-align: center;
    }
  }

  &__info {
    display: block;
    width: 300px;
    margin: 0 auto;
    margin-top: 5px;
  }

}