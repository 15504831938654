
.ride-schedule__item {

  &__date {
    margin-top: 10px;
    padding: 10px 0 0 0;
    border: solid 1px #eee;
    border-radius: $border-radius-base $border-radius-base 0 0;
    text-align: center;
    color: $black;

    @media (min-width: $screen-sm) {
      border-bottom: none;
      border-right: 0;
      border-radius: $border-radius-base;
    }

    h4:first-child {
      text-transform: none;
      color: $black;
    }

    .ride-schedule__item--ended & {
      padding: 0;
      background: none;
      color: $grey;

      p:first-child {
        padding-top: 10px;
        color: $grey;
        font-weight: bold;
      }
    }
  }

  @include clearfix();
  margin-bottom: 20px;

  &__title{
    margin-bottom: 10px;
    font-weight: bold;
  }

  &-list {
    margin: 0;
    background: $bcLightGrey;
    text-transform: uppercase;
    color: $grey;
    padding: 5px 0;
    border-top: 1px solid white;
    font-size: em(12);

    &:last-of-type {
      border-radius: 0px 0px 0px 4px;
    }

    &.icon {
      padding: 0;

      span {
        color: white;
        display: inline-block;
        line-height: 1;
        padding-bottom: 7px;
        font-size: em(27);

        &:before {
          position: relative;
          left: -2px;
          top: 5px;
        }
      }
    }

    .bc-creator {
      margin: 1px 0;
      &:before {
        content: "\4b";
        font-size: em(14);
        color: $bcGreen;
      }
    }

    .bc-ride-leader {
      margin: 1px 0;
      &:before {
        font-size: em(14);
        color: #085394;
      }
    }

    &--booked,
    &--attended,
    &--required,
    &--admin-required,
    &--created,
    &--leading {
      border-color-top: white;
    }

    // &--booked,
    // &--attended {
    //   background: #b6d7a8;
    // }
    &--incomplete {
      background: #f1c232;
      color: white;
    }

    &--admin-required {
      background: #cf2a27;
      color: white;
    }

    // &--created {
    //   background: #6aa84f;
    // }
    // &--leading {
    //   background: #085394;
    // }
  }

}