.carousel--full-width {
  background-color: $bcLightPurple;
  color: $white;

  .page-header {
    margin-top: $line-height-computed;
  }

}

.carousel-wrapper {}

.carousel-control.left,
.carousel-control.right {
  background: none;
}

.carousel-control--arrow {
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 5;
  margin-top: -.5em;
  font-size: 2em;
}

@media (max-width: $screen-sm-max) {
  .comment {
    text-align: center;
  }
}

@media (max-width: $screen-xs-max) {

  .carousel-control-wrapper,
  .carousel-control {
    padding-top: 60px;
  }

  .carousel-control {
    top: auto;
  }

  .carousel-control--arrow {
    position: relative;
  }
}



