

.form-group {

  @include clearfix();
  margin-bottom:25px;

  &:last-child {
    margin-bottom:0px;
  }

  &.has-error{
    padding-left: $padding-large-horizontal;
    border-left: solid $bcRed;
  }

}