
.footer__links {

  float: left;
  width: 100%;
  margin-top: $padding-large-vertical;
  padding-left: 0;
  text-align: center;

  li {
    display:block;
    margin:4px 0;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
      padding-right: $padding-large-horizontal;
      margin-right: $padding-large-horizontal;
      border-right: solid 1px $black;

      &:last-child {
        border:0;
      }
    }

    a {
      font-weight: normal;
      color: $black;
    }
  }

}