// Global colours used across for sites
// Product specific colours to be set in  assets/sass/products/product-name.scss
//Common colours
$black:         #333333;
$white:         #ffffff;
$grey: #666666;
$yellow: #fec002;
$turquoise: #68c5c2;

//Primary Colours
$bcPurple:        #312974;
$bcRed:           #d7000d;
$bcLightPurple:   #5b50af;
$bcGreen:         #2db564;
$bcLightGrey:     #e6e6ec;
$bcVeryLightGrey: #f8f7fc;

//Secondary Colours
$breezeRed:      #ee2945;
$breezeDarkRed:  #da223c;
$breezePink:     #fb8596;
$rideTurquoise:  #3eb6b4;
$rideLightTurquoise:  #3eb6b4;
$rideDarkTurquoise:  #30a7a5;
$bcYellow:   #f6eddc;
$bcFuchsia: #dc0e96;
$bcFormPurple:#F7F6FB;
$bcSuccess: #0b8854;
$bcSuccessBg: #e0f3eb;
$bcInfo: $bcLightPurple;
$bcInfoBg: #f5f4fc;
$bcWarning: $black;
$bcWarningBg: #faedba;
$bcDanger: $bcRed;
$bcDangerBg: #f9ebec;
$alert-info-border: $bcInfo;
$oranageRed : #FF4500;
$greenGuided : #009F4D;


.bcGreen {
  color:$bcGreen;
}

.bcRed {
  color:$bcRed;
}

.background--red {
  background: $bcRed!important;
}



$imageOverlayOpacity: .7;


/*
Bootstrap overwrites
*/
$brand-primary: $bcPurple;
$grey-light:  $bcLightGrey;
$alert-success-text: $bcSuccess;
$alert-success-bg: $bcSuccessBg;
$alert-info-text: $bcInfo;
$alert-info-bg: $bcInfoBg;
$alert-warning-text: $bcWarning;
$alert-warning-bg: $bcWarningBg;
$alert-danger-text: $bcDanger;
$alert-danger-bg: $bcDangerBg;

/*
  Typography
*/
$defaultTextColour: $black;

/*
Buttons
*/
$btn-default-bg: $brand-primary;
$btn-default-border: $brand-primary;
$btn-default-color: $white;

/*
Forms
*/
$input-border: $black;

/*
Navigation
*/

$navbar-default-link-color: $white;
$dropdown-link-color: $white;
$navbar-default-border: none;

/*
Date picker
*/

$daterangepicker-active-bg-color: $bcPurple;
$daterangepicker-in-range-bg-color: $bcLightGrey;


/*
Green for bc assessed check
*/

$bcAssessedGreen: #0d8754;

/*
Bootstrap switch
*/

$bootstrap-switch-handle-primary: $bcPurple;
