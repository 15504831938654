.send-message-wrapper{
  position: relative;
  margin-top: 20px;

  &__leave{
    margin-top: 20px;
  }

  &__submit{
    margin-top: 20px;
  }
}