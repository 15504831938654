// styles take from daterangepicker to match
$arrow-suffix-size: ($arrow-size - $daterangepicker-border-size);

.popover.clockpicker-popover.bottom {
  margin-top: 2px
}

.clockpicker-popover.popover {
  border-radius: $daterangepicker-border-radius;

  .popover-title {
    border-color: $daterangepicker-border-color;
    border-radius: $daterangepicker-border-radius $daterangepicker-border-radius 0 0;
  }
}

.clockpicker-popover .popover-content {
  background-color: $white;
}

.clockpicker-plate {
  border-color: $daterangepicker-border-color;
}


.clockpicker-align-left.popover > .arrow {
  left: 18px;
  top: -14px;
  border-width: 7px;
  border-bottom-color: $daterangepicker-border-color;


  &:after {
    top: 1px;
    left: 4px;
    border-right: $arrow-suffix-size solid transparent;
    border-bottom: $arrow-suffix-size solid $daterangepicker-bg-color;
    border-left: $arrow-suffix-size solid transparent;
    z-index: 3;
  }
}



