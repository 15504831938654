.listings__filter-form__more {
  display: inline-block;
  //margin-top: -15px;
  margin-bottom: $padding-large-vertical * 2.5;
  padding: $padding-large-vertical $padding-large-horizontal;
  background: $bcLightGrey;
  box-shadow: $rideShadow;
  border-radius: $border-radius-large;
  font-weight: 500;
  font-size: em(18);
  color: $black;
  transition: $transition all ease;
  text-decoration:none!important;



  span {
    margin-right: 5px;
    vertical-align: text-bottom;
    &:before {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.collapsed {
    background: none;
    box-shadow: none;
    border-radius: 0;

    &:hover {
      background: $bcLightGrey;
      box-shadow: $rideShadow;
      border-radius: $border-radius-large;
    }

    span:before {
      content: "\71";
    }
  }
}