  .navbar {

    //bootstrap override
    min-height:inherit;
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
    //end

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

    //border:3px solid lime;


    .container-fluid {
      padding-left:0;
      padding-right:0;

      .navbar-collapse {
        margin-left:0;
        margin-right:0;
        padding-left:0;
        padding-right:0;
      }
    }


  }

  .navbar-header {

    position: relative;
    margin:0!important;
    //border:3px solid red;
    height:90px;


    .navbar-brand {

      margin:20px 0 0 12px!important;
      padding:0;
      height:auto;
      width:110px;
      //border:3px solid purple;

      @media (min-width: $screen-sm-min) {
        margin:21px 0 0 12px!important;
        width:140px;
      }

      img {
        float:left;
      }

    }
  }