.dashboard-home__statistics__card {
  position: relative;
  padding: 15px;
  border: solid 3px;
  text-align: left;
  border-radius: $border-radius-base;

  h4{
    color: $black;
    text-transform: none;
  }
  
  h2 {
    margin: 0;
    margin-top: 30px;
    color: $black;
  }

  [class^="bc-"],
  [class*=" bc-"] {
    position: absolute;
    top: 0;
    right: 0;
    font-size: em(50);

    @media (min-width: $screen-lg-min) {
      font-size: em(70);
    }
  }
}