.route-listing {

  [class^="col-"] {
    padding-bottom: 0px;
  }

  .location, .elevation {
    position: relative;
    padding-left: 27px;
    margin-top: 6px;
    margin-bottom: 6px;
    i {
      font-size: 23px;
      position: absolute;
      left: 0px;
      top: -7px;
    }
  }

  .distance {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .rating {
    margin-top: 6px;
    margin-bottom: 6px;
    .count {
      font-size: 12px;
      position: relative;
      top: -3px;
      left: 3px;
    }
  }

  .assessed {
    margin-top: 6px;
    margin-bottom: 6px;
    i {
      background-color: $bcAssessedGreen;
      border-radius: 50%;
      color: white;
      display: inline-block;
      padding: 1px 4px;
      font-size: 12px;
      position: relative;
      top: -2px;
      &:before {
        position: relative;
        top:2px;
        left:0;
      }
    }
  }

  .image-container {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.summary-block {
    .content-container {
      padding-bottom: 0;
    }
    h3 {
      margin-top: 5px;
    }

    .property {
      margin: 10px;
    }

    .image-container {
      margin-bottom: 0;
    }
  }

  .button-container {

    /* only md and above */
    @include over-md {
      margin-top: 25px;
      position: relative;
      right: 16px;
    }
  }
}