.form-dd-day,
.form-dd-month,
.form-dd-year {
  display: inline-block;
}


.date-container {

  .styled-select {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 5px;

    @media (min-width: $screen-ms-min) {
      width: 31%;
      margin: 0 5px 0 0;
    }
  }
}

