a {
  //font-weight: bold;
  color: $bcLightPurple;
  font-weight:bold;
}

.highlighted-link {
  font-weight: 600;

  input + & {
    //padding-top: $line-height-half;
  }
}