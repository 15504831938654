.groups-cta {

  .icon-circle {
    float: left;
    margin-right: 10px;
  }

  .white-card {
    @media (min-width: $screen-md) {
      height: 280px;
    }
  }

  h4 {
    float: left;
    width: 100%;
    text-align: left;
  }

  h5 {
    //display:inline-block;
    position: relative;
    margin: 0 0 10px 0;
    color: $bcPurple;
    height:1.1em;
    padding:1px;
    overflow:hidden;
    //padding-right:18px;
    //
    //&:after {
    //  content:"...";
    //  position: absolute;
    //  right:0;
    //  bottom:0;
    //}
  }

  ul {
    margin-bottom: 0;
  }

  li {
    @include clearfix();
    margin-top: 10px;
  }

  &__image {
    float: left;
    width: 50px;
    margin-right: 15px;
  }
}