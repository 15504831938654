/*
Fonts
*/
@font-face {
  font-family: 'handelgothic_btregular';
  src: url('/fonts/handelGothic/handel-gothic-bold-webfont.eot');
  src: url('/fonts/handelGothic/handel-gothic-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/handelGothic/handel-gothic-bold-webfont.woff') format('woff'),
  url('/fonts/handelGothic/handel-gothic-bold-webfont.ttf') format('truetype'),
  url('/fonts/handelGothic/handel-gothic-bold-webfont.svg#handelgothic_btregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*
Bootstrap overwrites
*/
$font-family-sans-serif: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-family-sans-serif-regular: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$headings-font-family: 'handelgothic_btregular';

$line-height-computed: 30px;

//$headings-font-family: Handel Gothic;

$font-size-base: 16px;
$font-size-h1: em(36);
$font-size-h2: em(28);
$font-size-h3: em(22);
$font-size-h4: em(18);


// reduce font sizes of bold body text as it looks massive
$bold-font-size: 0.9em;



