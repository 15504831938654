
.input-group-search {
  background: $white;

  &.input-group-search-border{
    border: solid 2px $black;
  }

  .has-error & {
    border-color: $bcRed;
    ///padding-left: $padding-large-horizontal;
  }

  input[type=text] {
    box-shadow: none;
    border: 0;
  }

  .btn {
    padding: $padding-base-vertical $padding-base-horizontal;
    background: $white;
    box-shadow: none;
    border-radius: 0 $border-radius-base;
    border: 0;
    font-size: 16px;
    color: $black;

    &:hover {
      i {
        color: $black;
      }
    }
  }

  .dropdown-menu > li > a {
    color: $black;
  }

  i {
    color: $bcRed;
    transition: color $transition ease;
  }
}