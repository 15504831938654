.dashboard-home__statistics {
  //extend .col-xs-12;
  //extend .col-md-6;
  margin-bottom: 20px;
  text-align: center;

  .white-card{
    float: left;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin: 0;
    }

    @media (min-width: $screen-md) {
      height: 580px;
    }
  }

  h3{
    margin-top: 15px;
    text-transform: none;
    color: $bcLightPurple;
  }

  small,
  .btn-default{
    margin-top: 20px;
  }
}