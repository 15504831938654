.featured-news {
  position: relative;
  padding: $padding-large-vertical * 8 0;
  background: $bcLightGrey;
  text-align: center;

  h2{
    margin-top: 0;
  }

  .block-news{
    margin-top: $padding-large-vertical * 2;
    margin-bottom: $padding-large-vertical * 4;
  }
}