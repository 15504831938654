.daterangepicker {
  td.disabled.off,
  option.disabled.off {
    visibility: visible;
  }

  td.off {
    visibility: hidden;
  }

  .input-mini {
    display: none;
  }

  .daterangepicker_input .glyphicon-calendar {
    display: none;
  }

  .btn{
    padding: $padding-small-vertical $padding-base-horizontal;
    border-radius: $border-radius-small;
    font-family: $font-family-sans-serif-regular;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    box-shadow: none;
  }

  .btn-default{
    background: $white;
    border-color: $bcLightGrey;
    color: $black;
  }

  .btn-success{
    background: $bcPurple;
    border-color: $bcPurple;
  }

  select.monthselect, select.yearselect {
    border: 1px solid #e6e6ec;
    border-radius: 3px;
    padding: 5px;
  }

  .ranges ul {
    li {
      border: 2px solid $bcPurple;
      background-color: transparent;
      color: $bcPurple;
      border-radius: 14px;
      text-transform: uppercase;
      font-size: 12px;
      font-family: handelgothic_btregular;

      &:hover, &.active {
        background-color: $bcPurple;
        color: white;
      }
    }
  }
}