.navbar .dropdown{
  [class^="bc-"],
  [class*=" bc-"] {
    position: absolute;
    top:50%;
    right:10px;
    width:16px;
    height:16px;
    transform:translateY(-50%);
  }
}

.navbar-nav .dropdown-menu,
.navbar-nav .open .dropdown-menu {

  padding: 0;
  background: $bcLightPurple;
  right: initial;
  border-radius: 0 0 $input-border-radius $input-border-radius;

  .divider {
    margin: 0;
  }
}

.dropdown-menu > li > a {
  padding:15px
}