.message-block {
  @include clearfix();
  width: 100%;
  margin-bottom: 20px;

  .sender {
    img {
      display: block;
      margin: 0 auto;

      @media (min-width: $screen-md) {
        width: 90px;
      }
    }
  }

  .sender-name {
    margin-bottom: 5px;
    font-weight: bold;
    color: $bcPurple;
  }

  .message {
    position: relative;
    width: 100%;
    padding: 20px;
    background: $bcLightGrey;

    p {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 15px;
      left: -13px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7.5px 13.0px 7.5px 0;
      border-color: transparent $bcLightGrey transparent transparent;
    }
  }

  small {
    padding-top: .5em;
    float: left;
  }

  &.my-message {

    float: right;

    .sender,
    .message {
      float: right;
    }

    .sender-name{
      color: $bcLightPurple;
      text-align: left;
    }

    .message{
      background: $bcLightPurple;
      color: $white;
    }

    .message:before {
      content: '';
      position: absolute;
      top: 15px;
      left: auto;
      right: -13px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7.5px 0 7.5px 13.0px;
      border-color: transparent transparent transparent $bcLightPurple;
    }

    .message a {
      color: $white;
    }
  }

}