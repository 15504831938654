.routes-cta {
  text-align: center;

  .white-card {
    @media (min-width: $screen-md) {
      height: 280px;
    }
  }

  h4 {
    float: left;
    width: 100%;
    text-align: left;
  }

  h5 {
    margin: 0 0 10px 0;
    color: $bcPurple;
    text-transform: none;

  }

  &__list {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    li {
      @include clearfix();
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        padding: 0 10px;

        + a {
          border-left: solid 2px $bcPurple;
        }
      }

      span {
        color: $bcLightPurple;
        font-size: em(22);
        vertical-align: middle;

        &:before {
          vertical-align: top;
        }
      }
    }
  }

  .icon-circle {
    float: left;
    margin-right: 10px;
  }

  &__image {
    float: left;
    width: 50px;
    margin-right: 15px;
  }
}