.user-list {
    @include clearfix();

    padding: 0;

    li {
        list-style: none;
        margin-bottom: $line-height-half;
        padding-top: 0;

        display: table;

        div.profile-container {
            display: table-cell;
            vertical-align: middle;
            width: 120px;
        }

        > a, > .table-cell {
            display: table-cell;
            vertical-align: middle;
        }
    }


    /*a {
         font-weight: 600;
     }

    .profile-container {
        margin-right: $h-space-double;
    }

    .profile-container + a {
        position: relative;
        top:-48px;
    }*/
}