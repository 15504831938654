
.ride-schedule__item {
  @include clearfix();
  margin-bottom: 20px;

  &__title{
    margin-bottom: 10px;
    font-weight: bold;
  }

  .content-container p {
    margin-bottom: 10px;
  }
}

.schedule-date.col-2 {
  padding-bottom: 0;
}

// Add new ride schedule filters here
// We always hide "Rides requiring a leader", *unless* the box is ticked
[data-requires-leader="1"] {
  display: none;
}

[data-created="1"],
[data-leader="1"],
[data-upcoming="1"],
[data-product] {
  display: none;
  .filter--show-all & {
    display: block; // Always show rides (apart from "Rides requiring a leader") if we've ticked no boxes
  }
}

.created--filter-show [data-created="1"],
.leader--filter-show [data-leader="1"],
.upcomming--filter-show [data-upcomming="1"],
.requires-leader--filter-show [data-requires-leader="1"] {
  display: block;
}
.created--filter-show [data-created="0"],
.leader--filter-show [data-leader="0"],
.upcomming--filter-show [data-upcomming="0"],
.requires-leader--filter-show [data-requires-leader="0"] {
  display: none !important;
}
// If we *don't* tick the "Rides requiring a leader" box, *hide* any rides that require a leader
#ride-schedule:not(.requires-leader--filter-show) [data-requires-leader="1"] {
  display: none;
}
// If we're booked on a ride, show the ride regardless of the "requires leader" box
// So -- if we have a list that hasn't been filtered by created, leader or upcoming, always show data booked
#ride-schedule:not(.created--filter-show):not(.leader--filter-show):not(.upcoming--filter-show) [data-booked="1"] {
  display: block !important;
}