.panel-default {

  border:0;
  box-shadow:none;

  .panel-heading {
    background: none;
  }

  .hide-panel-heading {
    display:none;
  }



}

// overide bootstrap styling
.panel-default > .panel-heading {
  color: $bcPurple;
}