
.form-risk-assessment-edit
{
  .people-at-risk {
    > p
    {
      &.should-show-true {
        display: block;
      }
      display: none;
    }
  }
}
