.navbar-nav {

  margin:0;

  > li > a {
    width: 100%;
    color: $black;
    font-weight: 500;
    padding:10px;
    line-height:initial;

    &:hover,
    &:focus {
      background: none;
      border-color: $bcLightPurple;
    }
  }
  .active a {
    color: $bcLightPurple;
    border-color: $bcLightPurple;
  }
  //Mobile dropdown active state
  > .open > a,
  > .open > a:focus {
    background: $white;
    color: $bcLightPurple;
    //border: 0;
  }


  @media (min-width: $screen-sm-min) {

    margin-top:10px;

    > li > a {
      transition: border $transition ease;
      border-bottom: solid 10px transparent;
      padding:35px 33px 35px 20px;
    }
  }
}