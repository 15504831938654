.featured-block {

  border:2px solid $bcLightGrey;
  border-radius: $block-border-radius;
  background-color: white;
  overflow:hidden;

  margin-bottom:20px;

  .image-container {

    //border:3px solid lime;
    height:0;
    padding-bottom:56.25%;
    overflow:hidden;
  }

  .google-map {
    height:0;
    padding-bottom:56.25%;
    //border:3px solid orangered;
  }


  .inner {
    text-align: left;
    padding: 20px;
  }

  .inner-details {
    padding-top:15px;
    height:175px;

    .resource-block & {
      height:180px;
    }


    div {
      margin-bottom:10px;
    }

    div.ratings-wrapper .rating {
      margin-bottom: 0;
    }

    .row {
      margin-top:30px;

      .downloads-link {
        border-left:2px solid $bcPurple;
        margin-bottom:0;
        text-align:left;

        &:first-child {
          border:0;
          text-align:right;
        }


      }
    }
  }

  &.featured-routes-row__item .inner-details {
    height: auto;
  }

  &.featured-routes-row__item .action {
    min-height: initial;

    a {
      margin: $line-height-half auto 0;
    }
  }


  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight:600;
    margin-bottom:10px;
  }

  .action {
    text-align: center;

    @media (min-width: $screen-sm-min) {
      min-height:100px;

      .resource-block & {
        min-height:30px;
      }

      .row {
        margin-top:30px;

        .col-md-6 {
          border-left:2px solid $bcPurple;
          margin-bottom:0;
          text-align:left;

          &:first-child {
            border:0;
            text-align:right;
          }
        }
      }
    }

    .btn {
      margin-bottom:20px;

      &+a {
        display:block;
        font-weight:bold;
      }
    }
  }

  .start-time,
  .start_time,
  .location,
  .places,
  .distance,
  .elevation,
  .members {
    position: relative;
    padding-left:30px;

    i {
      position: absolute;
      top: 2px;
      left:0;
      font-size:em(22);
    }
  }


  &.recommended-buddies {
    .profile-container {

      .profile-picture {

        margin-top:3%;
        max-width:50%;

      }
    }
  }
}

.js-ride-route-mapping-area {
  .google-map {
    min-height: 350px;
  }
}