.infoblocks-block {

  .row-block {
    padding: 5px 0;

    > h3, > h4, > div {
      margin: 15px 0;
    }
  }

  h2, h3, h4 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
  }

  min-height: 250px;
  padding-bottom: 20px;

  .image img {
    width: initial;
    height: 100%;
  }

  .image-large {
    height: 100px;
    margin: 24px 0 25px 0 !important;
  }

  .image-small {
    height: 60px;
  }

  .footer {
    padding: 8px 0 7px 0;
    display: block;
  }

  .leadout {
    height: 44px;
    overflow: hidden;
    padding: 0 10px;
  }
}
