.block_container-type_row-checklist {
  text-align: center;

  h2 {
    margin-bottom: 20px !important;
  }

  div.checklist-container {
    max-width: 400px;
    margin: 0 auto;
  }

  ul.checklist {
    list-style: none;
    position: relative;
    padding-left: 0;
    text-align: left;
    margin: 0 20px;

    li {
      padding: 10px 0;
      &:before {
        font-family: "bc-icons" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\2b";
        float: right;
        font-size: 1.6em;
        position: relative;
        top: -1px;
      }
    }
  }
}