// 88888888ba,                   ad88                           88
// 88      `"8b                 d8"                             88    ,d
// 88        `8b                88                              88    88
// 88         88   ,adPPYba,  MM88MMM  ,adPPYYba,  88       88  88  MM88MMM
// 88         88  a8P_____88    88     ""     `Y8  88       88  88    88
// 88         8P  8PP"""""""    88     ,adPPPPP88  88       88  88    88
// 88      .a8P   "8b,   ,aa    88     88,    ,88  "8a,   ,a88  88    88,
// 88888888Y"'     `"Ybbd8"'    88     `"8bbdP"Y8   `"YbbdP'Y8  88    "Y888

// ===============================================================
// default styles styles

.listings__filter-form {

  .checkbox {
    float: left;
    width: 100%;
    margin: 0;
    text-align: left;
    background-color: inherit;
    padding:3px 0 3px 20px;

    @media (min-width: $screen-ms-min) {
      width:50%;
    }

    &:first-of-type {
      clear: left;
    }

    label {
      width: 100%;
      font-weight: normal;
      min-height:auto;
      margin-bottom:0;
    }

    input {
      top:8px;
    }
  }

  select {
    //box-shadow: $rideShadow;
  }

  &__sort {
    clear:both;
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: $padding-large-vertical * 2;

    label {
      font-weight: 700;
      margin-right:20px;
      display:inline-block;
    }

    .styled-select {
      width: 70%;
    }

    .form-control {
      border-color: $bcLightGrey;
      //box-shadow: $rideShadow;
    }
  }

  &__date {
    label {
      float: none;
    }

    .form-control {
      //box-shadow: $rideShadow;
    }
  }

  &__submit{
    padding: $padding-large-vertical $padding-large-horizontal * 2;
  }

  //8b        d8   ad88888ba
  //Y8,    ,8P   d8"     "8b
  //  `8b  d8'    Y8,
  //    Y88P      `Y8aaaaa,
  //    d88b        `"""""8b,
  //  ,8P  Y8,            `8b
  // d8'    `8b   Y8a     a8P
  //8P        Y8   "Y88888P"

  //@media (min-width: $screen-xs-min) {
  //
  //}

  //88b           d88  88                    ad88888ba                                   88  88
  //888b         d888  ""                   d8"     "8b                                  88  88
  //88`8b       d8'88                       Y8,                                          88  88
  //88 `8b     d8' 88  88  8b,dPPYba,       `Y8aaaaa,    88,dPYba,,adPYba,   ,adPPYYba,  88  88
  //88  `8b   d8'  88  88  88P'   `"8a        `"""""8b,  88P'   "88"    "8a  ""     `Y8  88  88
  //88   `8b d8'   88  88  88       88              `8b  88      88      88  ,adPPPPP88  88  88
  //88    `888'    88  88  88       88      Y8a     a8P  88      88      88  88,    ,88  88  88
  //88     `8'     88  88  88       88       "Y88888P"   88      88      88  `"8bbdP"Y8  88  88

  //@media (min-width: $screen-ms-min) {
  //}

  //ad88888ba                                   88  88
  //d8"    "8b                                  88  88
  //Y8,                                          88  88
  //`Y8aaaaa,    88,dPYba,,adPYba,   ,adPPYYba,  88  88
  //  `"""""8b,  88P'   "88"    "8a  ""     `Y8  88  88
  //        `8b  88      88      88  ,adPPPPP88  88  88
  //Y8a     a8P  88      88      88  88,    ,88  88  88
  //"Y88888P"   88      88      88  `"8bbdP"Y8  88  88

  //@media (min-width: $screen-sm-min) {
  //}

  //88b           d88                       88  88
  //888b         d888                       88  ""
  //88`8b       d8'88                       88
  //88 `8b     d8' 88   ,adPPYba,   ,adPPYb,88  88  88       88  88,dPYba,,adPYba,
  //88  `8b   d8'  88  a8P_____88  a8"    `Y88  88  88       88  88P'   "88"    "8aw
  //88   `8b d8'   88  8PP"""""""  8b       88  88  88       88  88      88      88
  //88    `888'    88  "8b,   ,aa  "8a,   ,d88  88  "8a,   ,a88  88      88      88
  //88     `8'     88   `"Ybbd8"'   `"8bbdP"Y8  88   `"YbbdP'Y8  88      88      88

  @media (min-width: $screen-md-min) {
    .checkbox {
      width: 50%;
    }
  }

  //88
  //88
  //88
  //88           ,adPPYYba,  8b,dPPYba,   ,adPPYb,d8   ,adPPYba,
  //88           ""     `Y8  88P'   "Y8  a8"    `Y88  a8P_____88
  //88           ,adPPPPP88  88          8b       88  8PP"""""""
  //88           88,    ,88  88          "8a,   ,d88  "8b,   ,aa
  //88888888888  `"8bbdP"Y8  88           `"YbbdP"Y8   `"Ybbd8"'
  //                                      aa,    ,88
  //                                      "Y8bbdP"

  //@media (min-width: $screen-lg-min) {
  //
  //}

  //88888888ba               88
  //88      "8b              ""                 ,d
  //88      ,8P                                 88
  //88aaaaaa8P'  8b,dPPYba,  88  8b,dPPYba,   MM88MMM
  //88""""""'    88P'   "Y8  88  88P'   `"8a    88
  //88           88          88  88       88    88
  //88           88          88  88       88    88,
  //88           88          88  88       88    "Y888

  //@media print {}

}