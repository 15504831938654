.listings {
  form {
    @include clearfix();
  }
}

.listings__filter-form {


  .form-group {

    &.col-4 {
      //width: 100%;
    }

    &.col-12 {
      .checkbox {
        @media (min-width: $screen-ms-min) {
          width:auto;
          min-width:33%
        }
      }
    }

    @include over-sm() {
      &:nth-child(3n) {
        border: none;
      }
    }

    @include under-md() {
      &:nth-child(2n) {
        border: none;
      }
    }

  }


  .form-control {
    border-color: $bcLightGrey;
  }

  label {
    //float: left;
    display:block;
    width: 100%;
    clear: both;
    font-size:em(15.5);
    font-weight: 700;
    margin-bottom:10px;
  }

  .loader{
    //margin: 0 auto;
  }

  @media (min-width: $screen-sm-min) {
    .form-group {
      min-height: 120px;
      padding: 0 $padding-large-horizontal*1.3;
      border-right: solid 2px $bcPurple;

      &.h-no-border {
        border: none;
      }

      &.col-4{
        //width: 33.3333333333%;
      }

      &:last-child {
        //padding-right: 0;
        border: none;
      }
    }
  }
}