
.breadcrumb {

  margin-top:20px;

  background: none;

  > li + li:before {
    color: $black;
  }

  a {
    color: $black;
    font-weight: normal;
  }

  > .active {
    color: $bcPurple;
    font-weight: bold;

    &:before{
      font-weight: normal;
    }
  }
}

.block_container-type_row-breadcrumbs .breadcrumb {
  margin-bottom: 15px;
}