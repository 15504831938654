$partnerSize: 120px;

.block_container-type_row-partners {

  ul.partner-list {
    list-style: none;
    padding-left: 0;

    li {
      display: inline-block;
      margin: 10px;
    }
  }
  .partner-image {
    width: $partnerSize;
    height: $partnerSize;

    img {
      max-width: 100%;
      max-height: 100%;
      width: initial;

    }
  }

  /*
  Center the images in parent containers
   */
  .partner-image {
    height: $partnerSize;
    text-align: center;  /* align the inline(-block) elements horizontally */
    font: 0/0 a;         /* remove the gap between inline(-block) elements */

    &:before {
      content: ' ';
      display: inline-block;
      vertical-align: middle;  /* vertical alignment of the inline element */
      height: 100%;
    }

    img {
      display: inline-block;
      vertical-align: middle;  /* vertical alignment of the inline element */
      font: 16px/1 Arial sans-serif;        /* <-- reset the font property */
    }
  }

  @include under-ms() {
    ul.partner-list {
      li {
        display: block;
        margin: 15px;
      }
    }
    .partner-image {
      margin: auto;
      height: inherit;
      max-height: $partnerSize;
      img {
        max-height: $partnerSize;
      }
    }
  }
}
