.modal-content {
  border-radius: $border-radius-small;
}

.modal-header {
  border-bottom: none;
}

.modal-body {
  .send-message-wrapper {
    margin-top: 0;
  }
}

.modal-footer {
  border-top: none;
}