.ride-schedule-filters {
  text-align: center;
  ul {
    margin: 0;
    vertical-align: top;
    list-style: none;
    text-align: left;

    @media (min-width: $screen-ms) {
      display: inline-block;
      margin: 0 10px;
    }

    @media (min-width: $screen-lg) {
      margin: 0 20px;
    }

    li {
      position: relative;
      padding-bottom: 15px;

      span {
        &:before{
          vertical-align: initial;
        }

        @media (min-width: $screen-sm) {
          position: absolute;
          top: 0;
          right: -50px;
        }
      }
    }

    @media (min-width: $screen-ms) {

      &:last-child {
        border-left: solid 2px $bcPurple;
      }
    }
  }
}