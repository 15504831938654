.dashboard-home__statistics__rides,
.dashboard-home__statistics__miles{
  position: relative;
  display: inline-block;
  height: 185px;
  width: 185px;
  margin-right: 15px;
  border: solid 10px $bcLightPurple;
  border-radius: 50%;
  text-align: center;

  @media (min-width: 520px) {
    height: 140px;
    width: 140px;
  }

  @media (min-width: 630px) {
    height: 185px;
    width: 185px;
  }

  @media (min-width: 991px) {
    height: 140px;
    width: 140px;
  }

  @media (min-width: 1200px) {
    height: 170px;
    width: 170px;
  }

  h2 {
    margin: 0;
    color: $black;
    font-size: $font-size-h1;

    @media (min-width: 520px) {
      font-size: em(26);
    }

    @media (min-width: 630px) {
      font-size: $font-size-h1;
    }

    @media (min-width: 991px) {
      font-size: em(26);
    }

    @media (min-width: 1200px) {
      font-size: $font-size-h1;
    }
  }

  p {
    margin-bottom: 0;
  }

  &__inner{
    @include centerer();
  }
}

.dashboard-home__statistics__rides {
  &.red {
    border-color: $bcRed;
  }

  &.green {
    border-color: $greenGuided;
  }

  &__inner {
    p {
      font-size: em(16);
      line-height: 16px;
    }

    @media (min-width: 520px) {
      p {
        font-size: 16px;
      }
    }
  }
}

.dashboard-stats__block--total-rides {
  .highlight {
    &__rides {
      color: $bcLightPurple;
    }

    &__miles {
      color: $bcRed;
    }

    @media (min-width: 520px) {
      &__rides,
      &__miles {
        color: $grey;
      }
    }
  }

  .col-sm-8 small.col-12 {
    padding: 0;
  }
}

.more-stats {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;

  @media (min-width: 590px) {
    flex-direction: row;
  }

  .dashboard-stats__leader__block {
    position: relative;
    flex-basis: 50%;
    margin-bottom: 0;

    &:nth-child(1),
    &:nth-child(2) {
      @media (min-width: 590px) {
        border-bottom: solid 2px #fff;
      }
    }

    &:nth-child(odd) {
      @media (min-width: 590px) {
        border-right: solid 2px #fff;
      }
    }

    > span {
      display: flex;
      flex-direction: column;
    }
  }

  .dashboard-stats__leader__block__icon {
    margin: 5%;
  }
}
