.ride-schedule-controls {
  position: relative;
  bottom: -25px;
  z-index: 2;
  margin: 0 auto;
  width: 280px;
}

.ride-schedule-control:hover,
.ride-schedule-control:focus {
  text-decoration: none;
}

.ride-schedule-control.right {
  float: right;
}

.carousel-inner {
  .month-year {
    margin: 0;
    padding: 0 70px 50px;
    text-align: center;
    text-transform: none;
  }
}

.ride-schedule .loader {
  position: absolute;
  top: 48px;
}