.group-create{

  .radio,
  .checkbox {
    float: left;
    width: 100%;
    margin: 0;
    text-align: left;
    background-color: inherit;
    padding:3px 0 3px 20px;

    @media (min-width: $screen-ms-min) {
      width:50%;
    }

    &:first-of-type {
      clear: left;
    }

    label {
      width: 100%;
      font-weight: normal;
      min-height:auto;
      margin-bottom:0;
    }

    input {
      top:8px;
    }
  }
}