.share-btn {
  display: inline-block;
  width: 46px;
  height: 46px;
  font-size: 1.6em;
  color: $white;
  background-color: $bcPurple;
  border: $borderWidth solid transparent;
  padding: 7px 8px;
  border-radius: 30px;
  text-decoration: none;
  transition: color .3s, background-color .3s;
  line-height: inherit !important; // override addthis service styling

  &:hover,
  &:active,
  &:focus {
    color: $bcPurple;
    background-color: $white;
    border-color: $bcPurple;
    text-decoration: none;
  }
}

.share-btn--icon {
  position: relative;

  &.bc-fa-facebook,
  &.bc-fa-instagram {
    left: -1px;
  }
}