
// for tables where the columns wont behave well at narrow widths so
// force horizontal scrolling to view the table
.row-form {
  @include clearfix();
  width: 100%;
  min-width: 720px;

  &:first-child {
    border-top: 1px solid $bcLightGrey;
  }
}

// when we want full responsive columns
.row-form__full-width {
  @include clearfix();
  border-bottom: 1px solid $bcLightGrey;

  &:first-child {
    border-top: 1px solid $bcLightGrey;
  }
}
.row-form-label,
.row-form-cell {
  display: inline-block;
  float: left;
  text-align: left;
  padding: $line-height-half $table-cell-padding;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &.btn-col {
    text-align: right;
  }
}

//Vertical align table cells
.row-form__full-width {
  display:table;
  @include clearfix();
  width:100%;
}

.row-form-cell {
  display:table-cell;
  vertical-align:middle;
  //float:none;
  .btn {
    margin-top: 30px;
  }

  .btn.h-t-margin--zero {
    margin-top: 0;
  }
}

@include only-xs() {
  .row-form-label.col-xs-12,
  .row-form-cell.col-xs-12 {
    padding: $line-height-half 0;

    & + .row-form-label.col-xs-12,
    & + .row-form-cell.col-xs-12 {
      padding-top: 0;
    }
  }


}


// manage leader table widths set as required for individual columns
.manage-leader-col-1 { width: 35%;}
.manage-leader-col-2 { width: 15%; text-align:center;}
.manage-leader-col-3 { width: 35%;}
.manage-leader-col-4 { width: 15%; text-align:center;}
//.manage-leader-col-5 { width: 20%; }


// add bookees/riders table widths set as required for individual columns
.add-bookee-col-1 { width: 18%; }
.add-bookee-col-2 { width: 18%; }
.add-bookee-col-3 { width: 18%; }
.add-bookee-col-4 { width: 18%; }
.add-bookee-col-5 { width: 18%; }
.add-bookee-col-6 { width: 10%; }

// risk assessment table widths set as required for individual columns
.risk-assessment-col-1 { width: 35%; }
.risk-assessment-col-2 { width: 13%; }
.risk-assessment-col-3 { width: 17%; }
.risk-assessment-col-4 { width: 35%; }

// risk assessment additional risks table widths set as required for individual columns
.additional-risks-col-1 { width: 35%; }
.additional-risks-col-2 { width: 13%; }
.additional-risks-col-3 { width: 17%; }
.additional-risks-col-4 { width: 25%; }
.additional-risks-col-5 { width: 10%; }

// risk assessment additional risks edit table widths set as required for individual columns
// two rows of fields for each edit risk col 1-4 for top row, col 5-7 for bottom row
.additional-risks__edit-col-1 { width: 25%; }
.additional-risks__edit-col-2 { width: 25%; }
.additional-risks__edit-col-3 { width: 25%; }
.additional-risks__edit-col-4 { width: 25%; }
.additional-risks__edit-col-5 { width: 45%; }
.additional-risks__edit-col-6 { width: 45%; }
.additional-risks__edit-col-7 { width: 10%; }

// participants register table widths set as required for individual columns
.participant-register-col-1 { width: 80% }
.participant-register-col-2 { width: 20% }


.risk-highlighted {
 .h-no-border {
   border:0!important;
 }
}