

.footer__contact {
  /*margin-top: $padding-large-vertical * 2;
  padding-top: $padding-large-vertical * 2;*/
  border-top: solid 1px $white;


  .footer__contact-sponsor {
    text-align: left;
    img {
      height: 40px;
      width: inherit;
      margin-top: 17px;
      max-width: 100%;
    }
  }

  .footer__contact-contact {
    text-align: right;
    margin-top: 20px;
  }

  @include under-sm() {
    .footer__contact-sponsor {
      text-align: center;
    }

    .footer__contact-contact {
      text-align: center;
    }
  }
}