.conversation-messages {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;

  &__title {
    margin: 0;
    margin-bottom: 20px;
    padding: 0 20px;
    text-align: center;
  }

  .pagination {
    display:none;
  }
}