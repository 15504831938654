.dashboard-home {
  .ride-search {
    padding: 15px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    background: $bcLightPurple;
    text-align: center;
    border-radius: $border-radius-base;

    @media (min-width: $screen-md) {
      height: 130px;
    }

    .input-group {
      //extend .input-group-search;
      width: 100%;
    }

    h3 {
      display: block !important;
      margin-top: 10px;
      color: $white;
      text-transform: none;

      @media (min-width: $screen-md) {
        font-size: em(18);
      }

      @media (min-width: $screen-lg) {
        font-size: $font-size-h3;
      }
    }
  }
}