.bc-full-star {
  color: gold;
  display: inline-block;

  &.blank-star {
    color: darkgray;
  }
}

.half-star-wrapper {
  position: relative;
  display: inline-block;

  .bc-full-star {
    position: relative;
  }

  .bc-half-star {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    color: gold;
  }
;
}

.rating-stars {
  @include clearfix();
  width: auto;
  float: left;

  .rating-star {
    background: none;
    border: none;
    color: darkgray;
    float: right;

    &:hover {
      color: gold;

      & ~ .rating-star {
        color: gold;
      }
    }
  }

  .rating-star--selected {
    color: gold;

    & ~ .rating-star {
      color: gold;
    }
  }
}

.rating-stars--inline {
  display: inline-block;
  padding-right: $h-space;
}

span.rating {
  display: inline-block;
  margin: 0px 4px;
  position: relative;
  top: 1px;
}