.panel-heading {

  [data-toggle="collapse"] {
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    i:before {
      content: "\6d";
      font-size:em(12);
      margin-right:10px;
      display:inline-block;
    }

  }

  [aria-expanded="true"]{
    i:before {
      content: "\6c";
    }
  }

}


.panel-border,
.profile-dashboard,
.edit-profile,
.create-ride,
.group-create{

  .panel {
    margin-bottom: 20px;
  }

    .panel-heading {
      position: relative;
      background: none;
      border-bottom: solid 2px $bcLightGrey;
      text-align:center;

      h2 {
        display: inline-block;
        font-size: 28px;
      }

      .edit-profile{
        position: absolute;
        top:50%;
        right:10px;
        transform:translateY(-50%);
        text-decoration: none;
        border:1px solid $bcLightGrey;
        padding:4px 12px 6px 5px;
        border-radius: 4px;

        i {
          font-size:em(22);
          position: relative;
          top:5px;
          margin-right:3px;
        }
      }

    }
}

.panel-border,
.profile-dashboard,
.edit-profile,
.create-ride,
.group-create {

  .panel {

    border-bottom: 0;

    .panel-body {
      border: solid 2px $bcLightGrey;
      border-top:0;

      @media (min-width: $screen-sm-min) {
        padding:25px;
      }

      .panel-heading {
        border-bottom:0;
      }
    }

  }
}
