
.dashboard-navigation__actions {

  margin-top:15px;
  padding: $padding-large-vertical * 2 0;
  text-align: center;

  @media (min-width: $screen-md-min) {
    float: right;
    width: 10%;
  }

  .btn {
    display: inline-block;
  }

  &__close {
    display: none!important;
    margin-left: $padding-large-horizontal;
    font-size: em(22);

    @media (min-width: $screen-sm-min) {
      display: inline-block!important;
      vertical-align:top;
      margin-top:33px!important;
    }



    &:before {
      vertical-align: middle;
    }

  }
}