.header__search {

  position: relative;
  padding: $padding-large-vertical 0;
  background: $bcLightGrey;
  text-align: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);

  .input-group{
    border: 0;
    background: none;

    .btn{
      height: $input-height-base;
      border-radius: 0 $border-radius-base * 2 $border-radius-base * 2 0;
      vertical-align: middle;

      &.dropdown-toggle {
        margin-right: $padding-xs-horizontal * 0.5;
        border-radius: $border-radius-base * 2 0 0 $border-radius-base * 2;

        span {
          display: inline-block;
          vertical-align: top;
          + span {
            margin:2px 0 0 7px;
          }
        }


      }

    }//btn

  }//input group

  &__form {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin:0 auto;

    @media (min-width: $screen-ms-min) {
      width: 30%;
      min-width: 330px;
    }

    @media (min-width: $screen-sm-min) {
      min-width: 430px;
    }

    form{
      margin: 0;
    }

  }//header search form

}//header search


/*
RIDE SOCIAL LOGO
*/
.page-social {

  .header__search {
    background-color: $rideTurquoise;

    text-align: right;

    @media (min-width: $screen-sm-min) {
      text-align: center;
    }

    .container-fluid {

      //&:before {
      //  content:'';
      //  width:130px;
      //  height:44px;
      //  margin-bottom:10px;
      //  background-image:url('/images/ride-social-logo-white.svg');
      //  background-size:contain;
      //  background-repeat:no-repeat;
      //
      //  @media (min-width: $screen-ms-min) {
      //    position: absolute;
      //    top:0;
      //    left:10px;
      //  }
      //}//:before
    }//container fluid

    p {
      color:$white;
    }
  }

}




/*
BREEZE LOGO
*/
.page-breeze {

  .header__search {
    background-color: $breezeRed;

    text-align: right;

    @media (min-width: $screen-sm-min) {
      text-align: center;
    }


    .container-fluid {

      //&:before {
      //  content:'';
      //  width:130px;
      //  height:44px;
      //  margin-bottom:10px;
      //  background-image:url('/images/breeze-logo-white.svg');
      //  background-size:contain;
      //  background-repeat:no-repeat;
      //
      //  @media (min-width: $screen-ms-min) {
      //    position: absolute;
      //    top:0;
      //    left:10px;
      //  }
      //}//:before
    }//container fluid

    p {
      color:$white;
    }
  }

}