/*
  Show and hide modifiers
*/
.h-hide,
.h-no-js__show,
.no-js .h-no-js__hide,
[aria-expanded="true"] > .h-expanded-hide,
[aria-expanded="false"] > .h-expanded-show {
  display: none;
}

.no-js .h-no-js__show,
[aria-expanded="false"] > .h-expanded-hide,
[aria-expanded="true"] > .h-expanded-show {
  display: block;
}

.h-no-border {
  border: none;
}
.h-no-shadow {
  box-shadow: none;
}

.h-absolute {
  position: absolute;
}

.h-no-caps {
  text-transform: none;
}


/*
  Float modifiers
*/
.h-float--none {
  float: none;
  @include clearfix();
}

/*
  Margin modifiers
*/
.h-t-margin--line-height-half {
  margin-top: $line-height-half;
}

.h-t-margin--line-height-computed {
  margin-top: $line-height-computed;
}

.h-t-margin--zero {
  margin-top: 0;
}

.h-l-margin-h-space {
  margin-left: $h-space;
}

.h-r-margin-h-space {
  margin-right: $h-space;
}

.h-b-margin--line-height-half {
  margin-bottom: $line-height-half;
}

.h-b-margin--line-height {
  margin-bottom: $line-height-computed;
}

.h-b-margin--zero {
  margin-bottom: 0;
}

.h-b-margin-lg--zero {
  @include over-md {
    margin-bottom: 0;
  }
}

/*
  Padding modifiers
*/
.h-t-padding--line-height-quarter {
  padding-top: $line-height-quarter;
}

.h-b-padding--zero {
  padding-bottom: 0;
}

// remove padding from cols
[class*="col-"].h-col-padding--zero {
  padding-left: 0;
  padding-right: 0;
}

[class*="col-"].h-l-col-padding--zero {
  padding-left: 0;
}

[class*="col-"].h-r-col-padding--zero {
  padding-right: 0;
}

// text helpers
.h-text-left {
  text-align: left;
}

.h-text-center {
  text-align: center;
}

.h-text-right {
  text-align: right;
}

.h-font-bold {
  font-weight: 600;
}

// javascript helpers

.js-transition__stop {
  transition: none !important;
}

$types: margin padding;
$positions: null -top -left -right -bottom;
$sizes: (
  -xs: 5px,
  -sm: 10px,
  -ms: 15px,
  null: 20px,
  -med: 40px,
  -xl: 60px,
  -none: 0
);

@each $type in $types {
  @each $position in $positions {
    @each $size, $value in $sizes {
      .#{$type}#{$position}#{$size} {
        #{$type}#{$position}: $value;
      }
      .#{$type}#{$position}#{$size}-force {
         #{$type}#{$position}: $value !important;
       }
    }
  }
}

.no-gutter{
  padding-left: 0!important;
  padding-right: 0!important;
}

/*
inline checkbox list
*/
.h-list-third-sm {

  .checkbox-inline,
  .radio-inline {

    display:block;
    margin-bottom:10px;

    @media (min-width: $screen-sm-min) {
      display:inline-block;
      vertical-align: top;
      width:45%;
      margin-right:0;
    }
  }
}

.h-list-half-sm {

  .checkbox-inline,
  .radio-inline {

    display:block;
    margin-bottom:10px;

    @media (min-width: $screen-sm-min) {
      display:inline-block;
      vertical-align: top;
      width:48%;
      margin-right:0;
      margin-bottom:initial;
    }
  }
}

.vertical-center {
  display:table;
  min-height:100px;
  padding:0;

  &.vertical-center-100 {
    height: 100px;
  }

  @media (min-width: $screen-ms-min) {
    min-height:100px;
  }

  &--inner {
    display:table-cell;
    vertical-align:middle;
  }
}

$displays: block inline inline-block;

@each $display in $displays {
  .display-#{$display} {
    display: $display;
  }
}

$directions: null -top -left -bottom -right;
@each $direction in $directions {
  .border#{$direction} {
    border#{$direction}: 1px solid $panel-default-border;
  }
}

/* Apply this to an input to hide its contents when it is disabled */
.hide-value-when-disabled {
  &[disabled] {
    color: transparent !important;
  }
}

.text-right-force {
  text-align: right !important;
}

/* Over ride bootstrap modal button alignment */
.modal-footer {
  text-align: center !important;
}