.navbar--product {
  display: none;
  .navbar-nav a {
    padding-bottom: $padding-small-vertical;
    border: 0;
  }

  @media (min-width: $screen-sm-min) {
    display: block;
    .navbar-nav a {
      border-bottom: solid 5px transparent;
    }
  }

}