/*
Purely for reference
*/

/*

$screen-xs:                  320px !default;
$screen-ms:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;

*/


/*
Bootstrap overwrites
*/
$grid-gutter-width:  20px;
$border-radius-small: 10px;
$border-radius-large: 30px;
$breadcrumb-separator: '>';


/*
Transition variable for consistency
*/
$transition:  .3s;

/*
Box Shadows
*/

$inputShadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
$buttonShadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
$rideShadow: 0px 2px 2.7px 0.3px rgba(0, 0, 0, 0.2);

$cardShadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);

$borderWidth: 2px;
$wrapper-border-width: 2px;



/*
  Spacing
*/
$line-height-half: ($line-height-computed * 0.5);
$line-height-quarter: ($line-height-computed * 0.25);
$h-space: 15px; // generic spacing between text and containers, images and text etc
$h-space-double: ($h-space*2); // generic large spacing eg between components in titles
$icon-from-text-spacing: 10px; //icon specific spacing from text
$profile-image-80-offset: -33px; // when profile image is 80x80 offset pull content next to .profile-container up to center of image

/*
  Fixed widths
*/

$date-time-fixed-width: 320px; // width of full date and time when using body text style

/* DC added for debug */
.row {
  //border:1px solid black;
}

$block-border-radius: 3px;

/*
Forms
*/

$input-group-addon-bg: transparent;
$input-group-addon-border-color: transparent;
$input-border: $bcLightGrey;