.comment {

  cite {
    font-style: normal;
    font-weight: 600;
  }

  .rating {
    margin-bottom: $line-height-computed;
  }

  @media (min-width: $screen-md-min) {
    .profile-container {
      float: right;
      margin-right: $h-space;
    }
  }

  .profile-container {
    img {
      width: auto;
    }
    .leader-roles {
      //bottom:-15px;
    }
  }
}

.ratings-comment-form {
  padding: 20px;

  .sub-heading {
    color: $bcPurple;
  }

  .form-group {
    margin: $line-height-half 0;
  }

  .sub-heading {
    margin: 0;
  }

}

.rating-comments__error {
  display: block;
  color: $bcRed;

  ul {
    padding: 0;
    margin: $line-height-half 0 0;
  }

  li {
    list-style: none;
  }

}