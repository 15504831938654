.header-image-body-content {
  margin-bottom:10px;
}

.wysiwyg hr {
  margin:0;
  padding:0;
  visibility: hidden;
  clear:both;
  border:none;
}

.width-25 {
  width:100%!important;
  padding-bottom:15px;

  @media (min-width: $screen-ms-min) {
    width:24% !important;
  }

  &.pull-left {
    @media (min-width: $screen-ms-min) {
      width:46%!important;
      margin-right:4%;

      &:nth-child(2n+2) {
        margin-left:4%;
        margin-right:0;
      }
    }

    @media (min-width: $screen-sm-min) {
      width:22%!important;

      &:nth-child(2n+2) {
        margin-left:0;
        margin-right:4%;
      }

      &:nth-child(4n+4) {
        margin-right:0;
      }
    }
  }

  &.pull-right {
    @media (min-width: $screen-ms-min) {
      width:46%!important;
      margin-left:4%;

      &:nth-child(2n+2) {
        margin-right:4%;
        margin-left:0;
      }

    }

    @media (min-width: $screen-sm-min) {
      width:22%!important;

      &:nth-child(2n+2) {
        margin-right:0;
        margin-left:4%;
      }

      &:nth-child(4n+4) {
        margin-left:0;
      }
    }
  }

}

.width-50 {
  width:100%!important;
  padding-bottom:15px;

  @media (min-width: $screen-sm-min) {
    width:50%!important;

    &:nth-child(1n) {
      padding-right:20px;
    }
    &:nth-child(2n) {
      padding-right:0px;
      padding-left:20px;
    }
  }
}

.image {
  a {
    display:block;
  }
}

.image-container {

  position: relative;
  margin-bottom:20px;

  @media (min-width: $screen-ms-min) {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .icon-circle {
    width:120px;
    height:120px;
    display:block;
    margin:0 auto;
    @media (min-width: $screen-ms-min) {
      width:80px;
      height:80px;
    }
    &:before {
      font-size:em(60);
      @media (min-width: $screen-ms-min) {
        font-size:em(40);
      }
    }
  }

  .go-x-ride-local &,
  .breeze-bike-rides &,
  .ride-social &,
  .recommended-rides &,
  .city-rides & {

    &:before {
      content:('');
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:65px;
      background:rgba(255,255,255,0.7);
    }
  }


  a {
    display:block;
  }

  .product-logo {
    width: 100%;
    height: auto;
    display: block;

    .go-x-ride-local &,
    .breeze-bike-rides &,
    .ride-social &,
    .recommended-rides &,
    .city-rides & {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      max-width: 120px;
    }

    .go-x-ride-local & {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .breeze-bike-rides & {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .ride-social & {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .local-rides & {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .city-rides & {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .recommended-rides & {
      top: 6px
    }
  }


  .bc-ride-creator,
  .bc-ride-leader,
  .bc-needs-leader {
    background-color: $white;
    border-radius: 100%;
    position: absolute;
    top: -3px;
    left: 5px;
    width: 27px;
    height: 27px;
    &:before {
      position: absolute;
      font-size: em(18);
    }
  }


  .bc-ride-creator {
    &:before {
      top:2px;
      left:1px;
    }
  }

  .bc-ride-leader {
    left: auto;
    right: 0px;

    &:before {
      top:2px;
      left:1px;
    }
  }

  .bc-needs-leader {
    left: auto;
    right: 0;

    &:before {
      top: -2px;
      left: -2px;
    }
  }

}

.summary-block {

  .image-container{
    &:before {
      display: none;
    }

    .product-logo {
      position: relative
    }

  }
}

