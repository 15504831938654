.header__login {
  padding: $padding-large-vertical;
  text-align: center;

  a {
    margin-left: $padding-large-horizontal;
    color: $bcPurple;
    display: inline-block;
    vertical-align: top;
    margin-top:6px!important;

    &.dashboard-navigation__actions__close {
      margin-top: 0 !important;;
    }

    @media (min-width: $screen-sm-min) {
      //margin-top:38px;
    }
  }

  .alert a {
    margin: 0 !important;
    color: inherit;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  input[type="submit"] {
    display:inline-block;
    vertical-align:top;

    @media (min-width: $screen-sm-min) {
      //margin-top:32px;
    }
  }

  .form-group {
    width: 80%;
    margin: 0 auto $padding-large-vertical*2;
    text-align: left;
  }

  .js-login {
    padding-bottom:15px;
  }

  .panel {
    margin-bottom:0;

    .panel-heading {
      display:none;
    }
  }


  @media (min-width: $screen-sm-min) {
    .form-group {
      display: inline-block;
      vertical-align:top;
      width: auto;
      margin-right: $padding-large-horizontal;
    }

    .button-container {
      display:inline-block;
      vertical-align: top;
      margin-top:28px;
    }
  }


  @media (min-width: $screen-md-min) {
    .form-group {
      width: 300px;
    }
  }
}