$product-primary: $rideTurquoise;
$product-secondary: $rideDarkTurquoise;
$product-text:  $white;

.page-social,
.ride-social {
  h1,
  h2,
  a:not(.header a),
  .space-availability .icon {
    color: $product-primary;
  }

  ul li {
    border-color: $product-primary;
  }

  .btn-primary:not(.back-to-top) {
    background-color: $product-primary;
    border-color: $product-primary;
    color: $product-text;
    &:hover {
      background-color: $product-text;
      border-color: $product-primary;
      color: $product-primary;
    }
  }

  &.header__search {
    background: $product-primary;
    color: $product-text;
  }

  &.navbar {
    background: $product-secondary;
    a {
      color: $product-text;
      transition: all $transition ease;

      &:hover {
        background: $product-text;
        color: $product-secondary;
      }
    }
  }

  &.summary-block,
  .summary-block {
    border-color: $product-primary;

    .btn-default{
      color: $white;

      &:hover,
      &:focus {
        color: $bcPurple!important;
      }
    }

    .featured-ride-box{
      background-color: $product-primary;
      color: $white;
    }
    .women-only {
      background-color: $product-primary;
    }
  }

  .ride-schedule__item__details{
    border-color: $product-primary;
  }
}