.block_container-type_row-ctalist {
  text-align: center;

  h2 {
    margin-bottom: 20px !important;
  }

  div.ctalist-container {
    max-width: 400px;
    margin: 0 auto;
  }

  ul.ctalist {
    list-style: none;
    position: relative;
    padding-left: 0;
    text-align: left;
    margin: 0 20px;

    li {
      float: left;
      width: 50%;
      padding: 10px;

      a {
        width: 100%;
      }
    }
  }

  @include under-sm() {
    ul.ctalist {
      margin: 0 10px;

      li {
        padding: 5px;
      }
    }
  }
  @include under-xs() {
    ul.ctalist {
      margin: 0 5px;

      li {
        padding: 5px;
      }
    }
  }
}