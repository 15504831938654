
.search-location {

  p {
    display: none;
    float: left;
    margin: 10px $padding-large-horizontal 0 0;

    @media (min-width: $screen-sm-min) {
      display: block;
    }
    .input-group {
      border-color: $white;
      box-shadow: none;
    }

    [class^="bc-"],
    [class*=" bc-"] {
      color: $bcRed;
      vertical-align: middle;

      &:focus,
      &:hover {
        color: $bcPurple;
      }
    }

    button:focus,
    input[type=submit]:focus {
      [class^="bc-"],
      [class*=" bc-"] {
          color: $bcPurple;
      }
    }

    .bc-fa-chevron-down{
      margin-left: $padding-large-horizontal;
    }

  }//p

}//search location