.series-frequency-options{
  margin-top: 20px;

  &__all-weekdays{
    float: left;
    width: 100%;
    margin-top: 20px;
    padding: 0;
    font-weight: bold;
  }

  &__end{
    margin-top: 20px;
  }
}