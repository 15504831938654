.details-starting-block {
  background-color: $bcVeryLightGrey;
  padding:20px;

  .title {
    font-weight:bold;
    font-size:em(18);
    position: relative;
    padding-top:10px;
    padding-left:35px;
    margin-top: $line-height-computed;

    [class^="bc-"],
    [class*=" bc-"] {
      font-size:em(26);
      position: absolute;
      top: 4px;
      left:0;
      color: $black;

      &.bc-full-star {
        font-size: 1em;
        top: 11px;
        left: 5px;
      }
    }
  }

  .title:first-child {
    margin-top: 0;
  }
}