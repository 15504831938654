.ride-schedule__quick-details {
  float: left;
  width: 100%;
  margin-top: 10px;

  p {
    margin-bottom: 0;
  }

  .bc-location,
  .bc-profile{
    font-size: em(26);
    vertical-align: middle;
  }

  .btn {
    float: right;
    margin-left: 10px;
  }

  .list-container {
    //extend .col-md-8;
    pading: 0;
    li {

      padding-bottom: $padding-large-vertical;
      min-height: 65px;

      @media (min-width: $screen-md-min) {

        border-left: 2px solid $bcPurple;
        float: left;
        width: 33.3%;
        padding-left: $padding-large-horizontal;

        &:nth-child(3n+1) {
          border: 0;
          padding-left: 0;
        }

      }

      &:last-child {
        padding-bottom: 0;
      }

      li {
        border: 0 !important;
        padding: 0 !important;
        width: 100%;

      }
    }
  }
}