[class^="col-"] {
  padding-bottom: $grid-gutter-width;
}

/**
Column classes built up from Bootstrap grid classes
**/
.col-12{
  @include make-xs-column(12);
  @include make-ms-column(12);
}

.col-10{
  @include make-xs-column(12);
  @include make-ms-column(10);
}

.col-8{
  @include make-xs-column(12);
  @include make-ms-column(6);
  @include make-sm-column(8);
}

.col-6{
  @include make-xs-column(12);
  @include make-ms-column(6);
}

.col-4{
  @include make-xs-column(12);
  @include make-ms-column(6);
  @include make-sm-column(4);
}

.col-3{
  @include make-xs-column(12);
  @include make-ms-column(6);
  @include make-sm-column(3);
}

.col-2{
  @include make-xs-column(2);
}