.loader {
  margin: 0 auto 7px;
  width: 40px;
  height: 40px;
  display: none;
  background-image: url('/images/loader.gif');
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.loader.loader--show {
  display: block;
}

.loader-wrapper {
  position: relative;
  display: inline-block;

  input.btn {
    margin-bottom:10px;
  }

  .loader {
    position: absolute;
    top: -3px;
    right: -60px;
  }
}
