.dashboard-home__statistics__card--rides-led {
  border-color: $breezeRed;
  background: $breezeRed;

  h2,
  h4{
    color: $white;
  }

  [class^="bc-"],
  [class*=" bc-"] {
    color: $white;
  }
}