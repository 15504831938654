.menu-button {
  text-align: right;
  position: absolute;
  top:50%;
  right:5px;
  //padding:18px;
  transform:translate(0%, -50%);
  font-size:em(14);
  z-index:1000;

  @media (min-width: $screen-sm-min) {
  display:none;
  }
}
