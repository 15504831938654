.bc-ride-creator{
  color: $bcGreen;
  font-size: em(22);
}

.bc-ride-leader{
  color: $bcPurple;
  font-size: em(22);
}

/*
SVG needs resizing
*/
.bc-needs-leader{
  color: $yellow;
  font-size: em(27);
  &:before {
    position: relative;
    left:2px;
  }
}