table, .table{
   & > thead > tr > th {
    border-bottom-width: 1px;
  }

  label {
    font-size: 1em;
  }

  label.small {
    font-size: $font-size-small;
  }

  label + *,
  .btn + *,
  p + * {
    margin-top: 0;

    &:last-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.table-responsive {
  width: 100%;
  border-collapse: collapse;

  th {
    font-weight: bold;
  }

  td, th {
    padding: 15px 8px;
    border-top: 1px solid #ccc;
    text-align: left;
  }

  td {
    table {
      background: #f3f3f6;
    }

    table thead tr th,
    table tbody tr td {
      border: none;
      padding-top: 0;
      padding-bottom: 0;
    }

    table thead tr th {
      padding-top: 15px;
    }
  }

  .alt-bg {
    background: #f3f3f6;
  }
}


@media only screen and (max-width: 760px) {
  .table-responsive {
    border: none;
  }

  .table-responsive tr:nth-child(even) td {
    background: #f3f3f6;
  }

  .table-responsive,
  .table-responsive thead,
  .table-responsive tbody,
  .table-responsive th,
  .table-responsive td,
  .table-responsive tr {
    display: block;
  }

  .table-responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table-responsive td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 65px;
    line-height: 60px;

    &:before {
      position: absolute;
      top: 50%;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      font-weight: bold;
      word-wrap: break-word;
      transform: translateY(-50%);

    }

    .notes, p {
      line-height: 23px;
    }

    .td-div, .form-group {
      padding: 10px 0;
    }

    &:before {
      content: attr(data-th);
      line-height: 1;
    }

    .styled-select,
    .select2-container--default {
      margin-top: 0;
      margin-bottom: 0;
    }

    .table-responsive {
      tr:nth-child(even) td {
        background: #f3f3f6;
      }

      &__reverse {

      }
    }
  }

  td.submit {
    padding: 0;
    text-align: center;
    &:before {
      display: none;
    }

    input, button {
      width: 100%;
      margin: 15px 0;
    }
  }

  td.notes {
    line-height: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  td.full-row {
    padding: 0;
    width: 100%;
  }
}

td {

  .styled-select select {
    width: auto !important;
    line-height: 20px;
    &.full-width {
      width: 100% !important;
    }
  }
}

 .table-responsive.no-borders {
  tr td, tr th {
    border-top: none;
    border-bottom: none;
    padding-top: 15px;
    padding-bottom: 0;
  }
}

// Risk managment
td.route-risk {
  padding: 0;
  border: none;
  height: auto;
  min-height: 0;
}

.js-risk-edit {
  padding: 15px;
}

.risk-assessment-confirm-box {
  margin-top: 20px;

  .error-wrapper.set {
    border: 1px solid red;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 30px;
    display: table-cell;
    vertical-align: middle;

    .h-b-margin--line-height {
      margin-bottom: 0;
    }
  }
}

td.align-top, th.align-top {
  vertical-align: top;
}

.parsley-error {
   border-color: #d7000d !important;
}

#new-risk {

  .styled-select {

    select {
      width: 100% !important;

      &.parsley-error {
        border: solid 2px #d7000d !important;
      }
    }
  }
}
