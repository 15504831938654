.notifications-cta {
  .icon-circle {
    float: left;
    margin-right: 10px;
  }

  .white-card {
    @media (min-width: $screen-md) {
      height: 280px;
    }
  }

  h4 {
    float: left;
    width: 100%;
  }

  &__list {
    //float: left;
    margin-bottom: 0;
    li {
      @include clearfix();
      float: left;
      width: 100%;
      margin-bottom: 10px;
      margin-top: 5px;

      p {
        margin-bottom: 5px;
        margin-left: 35px;

        &.notifications-cta__description {
          margin-bottom: 10px;
        }
      }
    }
    span {
      float: left;
      margin-right: 10px;
      font-size: em(26);
    }
  }

  &__description {
    font-weight: bold;
    color: $bcPurple;
  }

}