.color-block {
  /*border-left: 3px solid red !important;*/

  &.default {
    background-color: $bcPurple;
  }
  &.breeze {
    background-color: $breezeRed;
  }
  &.social {
    background-color: $rideTurquoise;
  }
  &.light {
    background-color: $bcLightPurple;
  }
  &.white {
    background-color: $white;
  }
  &.grey {
    background-color: $bcLightGrey;
  }
  &.black {
    background-color: $black;
  }
  &.red {
    background-color: $bcRed;
  }

  /* Text colors underneath */

  /* Purple Text */
  &.white, &.grey {
    color: $black;
    h1, h2, h3, h4, h5, h6 {
      color: $bcPurple;
    }
  }

  /* Dark Grey Text */
  &.grey {
    color: $black;
    h1, h2, h3, h4, h5, h6 {
      color: $grey;
    }
  }

  /* White Text */
  &.default, &.breeze, &.social, &.light, &.black, &.red {
    color: $white;

    h1, h2, h3, h4, h5, h6 {
      color: $white;
    }

    a {
      color: $bcLightGrey;
    }
  }

  /* Circle scroll buttons */

  /* Purple buttons */
  &.white, &.grey {
    .btn-circle {
      background-color: $bcPurple !important;
      border-color: $bcPurple !important;
      color: $white !important;

      &:hover,
      &:focus {
        background-color: $white !important;
        border-color: $bcPurple !important;
        color: $bcPurple !important;
      }
    }
  }

  /* Inverse buttons */
  &.default {
    .btn-circle {
      background-color: $bcRed !important;
      border-color: $bcRed !important;
      color: $white !important;

      &:hover,
      &:focus {
        background-color: $white !important;
        border-color: $bcRed !important;
        color: $bcRed !important;
      }
    }
  }

  /* White buttons */
  &.default, &.breeze, &.social, &.light, &.black, &.red {
    .btn-circle {
      background-color: $white !important;
      border-color: $white !important;
      color: $bcPurple !important;

      &:hover,
      &:focus {
        background-color: $bcPurple !important;
        border-color: $white !important;
        color: $white !important;
      }
    }
  }

  &.default, &.black, &.light {
    .btn-default {
      border-color: $white !important;
    }
  }
}

.color-block-social {
  /*border-left: 3px solid red !important;*/

  &.default {
    background-color: $bcPurple;
  }
  &.breeze {
    background-color: $breezeRed;
  }
  &.social {
    background-color: $rideTurquoise;
  }
  &.light {
    background-color: $bcLightPurple;
  }
  &.white {
    background-color: $white;
  }
  &.grey {
    background-color: $bcLightGrey;
  }
  &.black {
    background-color: $black;
  }
  &.red {
    background-color: $bcRed;
  }

  /* Text colors underneath */

  /* Purple Text */
  &.white, &.grey {
    color: $black;
    h1, h2, h3, h4, h5, h6 {
      color: $bcPurple;
    }
  }

  /* Dark Grey Text */
  &.grey {
    color: $black;
    h1, h2, h3, h4, h5, h6 {
      color: $grey;
    }
  }

  /* White Text */
  &.default, &.breeze, &.social, &.light, &.black, &.red {
    color: $white;

    h1, h2, h3, h4, h5, h6 {
      color: $white;
    }
  }

  /* Circle scroll buttons */

  /* Purple buttons */
  &.white, &.grey {
    .btn-circle {
      background-color: $bcPurple !important;
      border-color: $bcPurple !important;
      color: $white !important;

      &:hover,
      &:focus {
        background-color: $white !important;
        border-color: $bcPurple !important;
        color: $bcPurple !important;
      }
    }
  }

  /* Inverse buttons */
  &.default {
    .btn-circle {
      background-color: $bcRed !important;
      border-color: $bcRed !important;
      color: $white !important;

      &:hover,
      &:focus {
        background-color: $white !important;
        border-color: $bcRed !important;
        color: $bcRed !important;
      }
    }
  }

  /* White buttons */
  &.default, &.breeze, &.social, &.light, &.black, &.red {
    .btn-circle {
      background-color: $white !important;
      border-color: $white !important;
      color: $bcPurple !important;

      &:hover,
      &:focus {
        background-color: $bcPurple !important;
        border-color: $white !important;
        color: $white !important;
      }
    }
  }

  &.default, &.black, &.light {
    .btn-default {
      border-color: $white !important;
    }
  }
}

.float-block {
  /*border: 3px solid orange;*/

  //box-shadow: $buttonShadow;
  border-radius: $block-border-radius;

  margin-bottom: 20px;

  &.recommended-block {
    box-shadow: none;
    margin-bottom:0;
  }

  &.resource-block {
    margin-top:60px; 
    box-shadow: none;
  }

  div.row:last-child {
    [class^="col-"] {
      padding-bottom: 0;
    }
  }
}

.row-block {
  /*border: 3px solid blue;*/
  padding: 32px 0px 28px 0px;
  position: relative;

  .block_container-type_row-content &:first-child {
    padding-bottom:0;
  }

  h1, h2, h3 {
    margin:0;
  }

  p:last-child {
    margin:0;
    padding:0;
  }
}

.cta-block {

  padding: 15px; /* This needs to be after the row-block above to override it when applied */
  text-align: center;

  form {
    margin: 0;
  }

  .leadin, .success {
    color: $white;
    font-weight: bold;
    display: inline-block;
    vertical-align:middle;
    p {
      margin: 0;
    }
  }

  .input {
    display: inline-block;
    vertical-align:middle;
    margin: 10px 10px;
    input {
      box-shadow: none;
      border: 0;

      &.parsley-error {
        border: 3px solid $btn-danger-border !important;
      }
    }
  }

  .cta {
    display: inline-block;
    margin: 10px 10px;

    .btn-reverse {
      background-color: transparent !important;

      &:hover,
      &:focus {
        background-color: $white !important;
        color: $bcPurple !important;
      }

    }
  }

  &.white, &.grey {
    .cta {
      .btn-reverse {
        background-color: $bcRed !important;
        color: $white !important;

        &:hover,
        &:focus {
          color: $bcPurple !important;
        }
      }
    }
  }

  .parsley-errors-list {
    display: none;
  }
}



.content-block {
  padding: 15px;

  h1, h2, h3 {
    margin-top: 15px;
  }

  &.white {
    padding: 0;
  }
}