// 88888888ba,                   ad88                           88
// 88      `"8b                 d8"                             88    ,d
// 88        `8b                88                              88    88
// 88         88   ,adPPYba,  MM88MMM  ,adPPYYba,  88       88  88  MM88MMM
// 88         88  a8P_____88    88     ""     `Y8  88       88  88    88
// 88         8P  8PP"""""""    88     ,adPPPPP88  88       88  88    88
// 88      .a8P   "8b,   ,aa    88     88,    ,88  "8a,   ,a88  88    88,
// 88888888Y"'     `"Ybbd8"'    88     `"8bbdP"Y8   `"YbbdP'Y8  88    "Y888

// ===============================================================
// default styles styles

.header__account--mobile {
  display:inline-block;
  vertical-align: middle;
  //margin:20px 0 0 15px;

    a{
      display: inline-block;
      padding:5px 10px;
      font-weight: 500;
      font-size: em(14);
      vertical-align: middle;

      &:first-child {
        border-right: solid 1px $bcPurple;
      }
    }

  .dropdown-toggle,
  .btn-account{
    margin-top: $padding-small-vertical;
    padding: $padding-small-vertical $padding-small-horizontal;
    border-width: 2px;
    font-size: em(11);
    //background: $white;
    //border-color: $bcPurple;
    //
    //&:hover,
    //&:focus {
    //  background: $bcPurple;
    //  color: $white;
    //}
  }

  .btn-account {
    display: inline-block;

    &.collapsed i:before{
      content: "\6d";
    }
  }

  //8b        d8   ad88888ba
  //Y8,    ,8P   d8"     "8b
  //  `8b  d8'    Y8,
  //    Y88P      `Y8aaaaa,
  //    d88b        `"""""8b,
  //  ,8P  Y8,            `8b
  // d8'    `8b   Y8a     a8P
  //8P        Y8   "Y88888P"

  //@media (min-width: $screen-xs-min) {
  //
  //}

  //88b           d88  88                    ad88888ba                                   88  88
  //888b         d888  ""                   d8"     "8b                                  88  88
  //88`8b       d8'88                       Y8,                                          88  88
  //88 `8b     d8' 88  88  8b,dPPYba,       `Y8aaaaa,    88,dPYba,,adPYba,   ,adPPYYba,  88  88
  //88  `8b   d8'  88  88  88P'   `"8a        `"""""8b,  88P'   "88"    "8a  ""     `Y8  88  88
  //88   `8b d8'   88  88  88       88              `8b  88      88      88  ,adPPPPP88  88  88
  //88    `888'    88  88  88       88      Y8a     a8P  88      88      88  88,    ,88  88  88
  //88     `8'     88  88  88       88       "Y88888P"   88      88      88  `"8bbdP"Y8  88  88

  @media (min-width: $screen-ms-min) {
    a {
      font-size: em(16);
    }

    .btn-account{
      display: none;
    }
  }

  //ad88888ba                                   88  88
  //d8"    "8b                                  88  88
  //Y8,                                          88  88
  //`Y8aaaaa,    88,dPYba,,adPYba,   ,adPPYYba,  88  88
  //  `"""""8b,  88P'   "88"    "8a  ""     `Y8  88  88
  //        `8b  88      88      88  ,adPPPPP88  88  88
  //Y8a     a8P  88      88      88  88,    ,88  88  88
  //"Y88888P"   88      88      88  `"8bbdP"Y8  88  88

  @media (min-width: $screen-sm-min) {
    display: none;
  }

  //88b           d88                       88  88
  //888b         d888                       88  ""
  //88`8b       d8'88                       88
  //88 `8b     d8' 88   ,adPPYba,   ,adPPYb,88  88  88       88  88,dPYba,,adPYba,
  //88  `8b   d8'  88  a8P_____88  a8"    `Y88  88  88       88  88P'   "88"    "8a
  //88   `8b d8'   88  8PP"""""""  8b       88  88  88       88  88      88      88
  //88    `888'    88  "8b,   ,aa  "8a,   ,d88  88  "8a,   ,a88  88      88      88
  //88     `8'     88   `"Ybbd8"'   `"8bbdP"Y8  88   `"YbbdP'Y8  88      88      88

  //@media (min-width: $screen-md-min) {
  //
  //}

  //88
  //88
  //88
  //88           ,adPPYYba,  8b,dPPYba,   ,adPPYb,d8   ,adPPYba,
  //88           ""     `Y8  88P'   "Y8  a8"    `Y88  a8P_____88
  //88           ,adPPPPP88  88          8b       88  8PP"""""""
  //88           88,    ,88  88          "8a,   ,d88  "8b,   ,aa
  //88888888888  `"8bbdP"Y8  88           `"YbbdP"Y8   `"Ybbd8"'
  //                                      aa,    ,88
  //                                      "Y8bbdP"

  //@media (min-width: $screen-lg-min) {
  //
  //}

  //88888888ba               88
  //88      "8b              ""                 ,d
  //88      ,8P                                 88
  //88aaaaaa8P'  8b,dPPYba,  88  8b,dPPYba,   MM88MMM
  //88""""""'    88P'   "Y8  88  88P'   `"8a    88
  //88           88          88  88       88    88
  //88           88          88  88       88    88,
  //88           88          88  88       88    "Y888

  //@media print {}
}