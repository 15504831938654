/*
  Font sizing
*/
@use "sass:math";

$font-size-small : floor(math.div(100% * $font-size-small, $font-size-base)); // bootstrap small calculation

body{
  color: $defaultTextColour;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  &.alt-heading {
    color: $bcPurple;
  }
}

*.white-text {
  color: $white;
}

// sub heading style follows h3 style sizes but inherits font and colour assigned to tag
.sub-heading {
  font-size: $font-size-h3;
  font-weight: 600;
  line-height: $headings-line-height;
  margin-top: $line-height-computed;
  margin-bottom: ($line-height-computed * 0.5);
}

.bold {
  font-weight: 600;
}

p{

  .introduction-text &{
    font-size: em(18);
  }
  .small-print &{
    font-size: em(12);
  }
}

.h3 {
  color: $bcPurple;
}