.cancelled-actions {
  @include borderWrapper();
  border-color: $alert-info-border;

  @include over-sm() {
    .featured-block {
      margin-bottom: 0;
    }
  }

}