.styled-list {
  padding: 0;
  li {
    list-style: none;
    margin-bottom: $padding-large-vertical;
    padding-left: $padding-base-horizontal;
    &:before {
      content: "\70";
      display: inline-block;
      vertical-align: top;
      font-family: "bc-icons";
      color: $bcPurple;
      margin-right: $padding-base-horizontal;
    }
  }
}