
/*
Colours
*/

.dashboard-navigation {

  ul {

    li {

      &.is-active,
      &:hover {

        background: $bcLightPurple;

        //ride social green
        //.page-social & {
        //  background-color: $rideTurquoise;
        //}

        //breeze red
        //.page-breeze & {
        //  background-color:$breezeRed;
        //}

        a {
          color: $white!important;
        }//:hover a

      }//:hover

      a {
        @media (min-width: $screen-md-min) {

          color:$bcPurple!important;
          border-left: solid 1px $bcLightPurple;

          //ride social green
          //.page-social & {
          //  border-left: solid 1px $rideTurquoise;
          //}

          //breeze red
          //.page-breeze & {
          //  border-left: solid 1px $breezeRed;
          //}
        }
      }//a

    }//li

    li:hover + li a,
    li.is-active + li a{
      border-left-color: $white;
    }


    li:hover + li.is-active a,
    li.is-active + li:hover a{
      border-left-color: $bcLightPurple;
    }
  }//ul

}//dashboard navigation





/*
Layout and styling
*/
.dashboard-navigation {

  .container-fluid {
    padding: 0;
  }

  ul {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    @include clearfix();

    @media (min-width: $screen-md-min) {
      width:90%;
    }

    li {
      transition: $transition background ease; //block at mobile

      @media (min-width: $screen-md-min) {
        float: left;
        width: 11%;
        padding: $padding-large-vertical * 2 0;
        text-align: center;

        &:first-child a {
          border:0;
        }
      }//li media query

      a {
        position: relative;
        display:block;
        text-decoration: none;
        font-weight: normal;
        font-size: em(20);
        font-family: $font-family-sans-serif-regular;
        transition: $transition color ease;
        padding: $padding-large-vertical 0 $padding-large-vertical 50px;

        @media (min-width: $screen-md-min) {
          display: block;
          padding: $padding-large-vertical 0;
          font-size: em(15);
        }//a media query


        span {

          position: absolute;
          top:50%;
          left:10px;
          transform:translateY(-45%);
          font-weight: 300;

          @media (min-width: $screen-md-min) {
            position: static;
            top:initial;
            left:initial;
            transform:none;
            display:block;
          }//span media query

          &:before {
            font-size:em(20);

            @media (min-width: $screen-md-min) {
              font-size:em(26);
            }
          }//:before

        }//span

      }//a

    }//li

  }//ul

}//dashboard navigation