.messages-add-buddies{
  margin-top: 20px;
  [class^="col-"] {
    padding-bottom: 0;
  }

  .leadin{
    margin-bottom: 10px;
  }

  &__form{
    .input-group{
      width: 80%;
    }

    .input-group-btn{
      font-size: em(15);
    }
  }
}