.featured-search {

  p{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-inline {
    width: 90%;
    margin: 0 auto;
  }

  .form-group {
    width: 100%;
    margin: $padding-large-vertical 0;
  }

  label {
    margin: 2px $padding-large-horizontal;
    font-family: $font-family-sans-serif-regular;
    text-align: left;
    display:inline-block;
  }

  .form-control {
    border-color: $white;
    @include placeholder( $bcPurple );
  }

  .styled-select {
    background: $white;
    border-radius: $input-border-radius;
    select {
      background: transparent;
      color: $bcLightPurple;
      width: 100%;
    }

    &:after {
      color: $bcPurple;
    }
  }


  @media (min-width: $screen-sm-min) {
    .styled-select {
      width: auto;
      min-width: 180px;
    }
  }

}