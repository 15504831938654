.social-block {
  padding: $padding-large-vertical * 3 0;
  text-align: center;
  ul {
   margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: $padding-large-vertical * 2;
      list-style: none;

      &:last-child{
        margin-right: 0;
      }
    }
  }

  i:before {
    display: inline-block;
    background: $white;
    padding: 5px;
    border-radius: 50%;
    font-size: em(26);
  }

}


.social-block {
  &.breeze {
    a {
      color:$bcRed;
    }
  }

  &.social {
    a {
      color:$rideTurquoise;
    }
  }
}