.author-horizontal {
	display: table;

	div	{
		float: none;
		display: table-cell;
		vertical-align: middle;

		.profile-container .leader-roles li,
		.profile-container .leader-roles li span img {
			width: 17px;
			height: 17px;
		}

		.profile-image {
			max-width: 100px;
			max-height: 100px;
			width: 100px;
			height: 100px;
		}
	}

	.col-md-9 {
		padding: 0;
	}


}

.author-notification {
	margin-top: 25px;
}