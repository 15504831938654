.profile-dashboard{

  &__title{
    font-weight: bold;
    margin-bottom: 0;

    &--for-btn{
      margin-bottom: 10px;
    }
  }

  &__actions{
    margin-top: 40px;
    text-align: center;

    .btn:first-child{
      margin-right: 15px;
    }
  }

  .panel-heading {
    h2{
      margin-top: 15px;

      a {
        font-weight:normal;
      }
    }
  }
}