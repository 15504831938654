.ride-cta {

  h3{
    margin-top: 10px;
    color: $white;
    text-transform: none;

    @media (min-width: $screen-md) {
      font-size: em(18);
    }

    @media (min-width: $screen-lg) {
      font-size: $font-size-h3;
    }
  }

  &__create {
    padding: 15px;
    background: $bcLightPurple;
    text-align: center;
    border-radius: $border-radius-base;

    @media (min-width: $screen-md) {
      height: 130px;
    }
  }
}