

//
//NOTIFICATION BLOCK
//
.notification-block {

  @include clearfix();
  @include borderWrapper();


  [class^="col-"] {
    padding-bottom: 0px;
  }

  a, .icon {
    //@extend .default-text-colour;
  }


  //
  //IMAGE CONTAINER
  //
  .image-container {
    //border:1px solid red;
    margin-bottom:10px;
    text-align: center;

    .icon-circle {
      width:50px;
      height:50px;
    }
  }


  //
  //CONTENT CONTAINER
  //
  .content-container {
    //border:1px solid black;
    margin-bottom:10px;

    @media (min-width: $screen-md-min) {
      padding-bottom:0;
    }

    //details container is used in the summary block, but I made it into
    //it's own partial as it's used elsewhere

  }


  //
  //BUTTON CONTAINER
  //
  .button-container {
    //border:1px solid lime;
    text-align:center;

    .btn {
      margin-right: $padding-large-vertical;
      //min-width:100%;
    }//btn

  }//button container



  .list-container {

    @include clearfix();

    li {

      padding-bottom: $padding-large-vertical;

      @media (min-width: $screen-md-min) {

        border-left: 2px solid $bcPurple;
        float: left;
        width: 33.3%;
        min-height:65px;
        padding-left: $padding-large-horizontal;

        &:nth-child(3n+1) {
          border: 0;
          padding-left: 0;
        }

      }

      &:last-child {
        padding-bottom: 0;
      }

      li {
        border: 0 !important;
        padding: 0 !important;
        width: 100%;
      }
    }
  }//list-container

  .alert{

    @media (min-width: $screen-ms-min) and (max-width: $screen-md-min) {
      margin-bottom: $line-height-half;
    }

  } // alert


  //
  //ICONS
  //
  .property {
    margin-top: 6px;
    margin-bottom: $line-height-half;
    position: relative;

    &:last-child {
      margin-bottom:0;
    }
  }

  .location,
  .elevation {
    padding-left: 27px;

    i {
      position: absolute;
      left: 0px;
      top: 0px;
      width:23px;
      height:23px;
      font-size: 23px;
      //border:1px solid black;

      &:before {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
    }
  }

  .rating {
    .count {
      font-size: 12px;
      position: relative;
      top: -3px;
      left: 3px;
    }

    .details &  {
      margin-top: $line-height-quarter;
    }
  }

  .assessed {
    padding-left: 27px;
    i {
      position: absolute;
      left: 0px;
      top: 0px;
      width:20px;
      height:20px;
      background-color: $bcAssessedGreen;
      border-radius: 50%;
      color: white;
      font-size:14px;

      &:before {
        position: absolute;
        top:3px;
        left:3px;
      }

    }
  }

  .details & .summary-block__login-container {
    margin-top: $line-height-computed;
  }
  
  ul strong, ul b {
    display: block;
  }
}




//
//LOGIN CONTAINER
//
.login-container {

  .panel {
    border:0;
    box-shadow:none;
    margin-bottom:0;
  }

  .panel-heading {
    background:none;
    padding:0;
    font-size:em(22);
    border:0;
  }

  .panel-body {
    padding-left:0;
    padding-right:0;
  }

  //btn spacing
  .btn[type="submit"] {
    margin-right:10px;
  }

  .button-container {
    text-align:center;
    @media (min-width: $screen-ms-min) {
      text-align:left;
    }

  }

  .dont-have-account {
    display:block;
    //margin-top:30px;
    text-align:center;
    margin-right:20px;

    @media (min-width: $screen-ms-min) {
      display:inline-block;
      margin-top:0;
    }
  }//dont have account

}//login container