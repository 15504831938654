.input-group {
  .input-group-btn {
    button.search {
      font-size: 19px;
      background-color: white;
      border: none;
      color: $bcRed;
      padding: 7px;
      border-bottom-right-radius: $input-border-radius;
      border-top-right-radius: $input-border-radius;
      height:$input-height-base;
    }
  }
}