.dashboard-stats__block--total-participants {

  .dashboard-home__statistics__rides {
    border-color: $bcGreen;
  }

  h3 {
    color: $bcGreen;
  }

}