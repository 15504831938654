// 88888888ba,                   ad88                           88
// 88      `"8b                 d8"                             88    ,d
// 88        `8b                88                              88    88
// 88         88   ,adPPYba,  MM88MMM  ,adPPYYba,  88       88  88  MM88MMM
// 88         88  a8P_____88    88     ""     `Y8  88       88  88    88
// 88         8P  8PP"""""""    88     ,adPPPPP88  88       88  88    88
// 88      .a8P   "8b,   ,aa    88     88,    ,88  "8a,   ,a88  88    88,
// 88888888Y"'     `"Ybbd8"'    88     `"8bbdP"Y8   `"YbbdP'Y8  88    "Y888

// ===============================================================
// default styles styles


.level-tag {
  position: relative;
  top:1px;
  padding:4px 8px;
  display: inline-block;
  border-radius: $border-radius-small;
  color: $white;
  &--easygoing {
    background: $bcGreen;
  }
  &--steady {
    background: $bcFuchsia;
  }
  &--challenging {
    background: $black;
  }
  &--mtb {
    background: #744d25;
  }
  &--mountain bike {
    background: #744d25;
  }
}