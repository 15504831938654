.grey-cta {
  float: left;
  padding: 30px;
  background: $bcLightGrey;
  border-radius: $border-radius-base;

  &__content{
    //extend .col-xs-12;
    //extend .col-md-10;
  }

  h3{
    margin-top: 0;
    text-align: left;
    text-transform: none;
  }

  p{
    text-align: left;
  }

  &__icon{
    //extend .col-xs-12;
    //extend .col-md-2;
    text-align: center;
  }

  [class^="bc-"],
  [class*=" bc-"] {
    font-size: em(70);
  }

  &.grey-cta--resources{
    h3,
    [class^="bc-"],
    [class*=" bc-"] {
      color: $bcRed;
    }
  }

  &.grey-cta--stats{
    h3,
    [class^="bc-"],
    [class*=" bc-"] {
      color: $bcLightPurple;
    }
  }
}