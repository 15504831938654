

/* only-xs includes xxs by default */
@mixin only-xs {
  @media (max-width: $screen-xs-max) {
    @content;
  }
}

@mixin only-xxs {
  @media (max-width: $screen-xs-min) {
    @content;
  }
}

@mixin only-sm {
  @media  (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin only-md {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin only-lg {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}


@mixin over-xs {
  @media  (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin over-sm {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin over-md {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}



@mixin under-xs {
  @media  (max-width: $screen-xs-min - 1) {
    @content;
  }
}

@mixin under-ms {
  @media  (max-width: $screen-ms-min - 1) {
    @content;
  }
}

@mixin under-sm {
  @media  (max-width: $screen-xs-max) {
    @content;
  }
}

@mixin under-md {
  @media (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin under-lg {
  @media (max-width: $screen-md-max) {
    @content;
  }
}
