%task--panel-wrappers {
  margin: 0;
  border: $wrapper-border-width solid $bcLightGrey;
  border-radius: $border-radius-base;
}

.task-panel {
  margin-bottom: $padding-large-vertical * 2;
}

.task-panel__header {
  @extend %task--panel-wrappers;
  padding: $line-height-half $h-space-double*2 $line-height-half $h-space-double;
  position: relative;
  z-index: 1;
  background-color: $white;
  color: $bcSuccess;
  font-size: 1em;
  text-transform: none;
  font-weight: inherit;

  .bc-glyphicon-chevron-up,
  .bc-glyphicon-chevron-down {
    position:absolute;
    top: $line-height-half;
    right: $h-space-double;
  }

  .bc-glyphicon-chevron-up {
    display: none;
  }

  &[aria-expanded="false"] {
    .bc-glyphicon-chevron-up {
      display: none;
    }
    .bc-glyphicon-chevron-down {
      display: block;
    }
  }


  &[aria-expanded="true"] {
    .bc-glyphicon-chevron-up {
      display: block;
    }
    .bc-glyphicon-chevron-down {
      display: none;
    }
  }
}

.task-panel__body {
  @extend %task--panel-wrappers;
  padding: $line-height-half $h-space-double;
  border-top: none;
  position: relative;
  top: -$border-radius-small; // nudge up so corners are hidden
  background-color: $bcVeryLightGrey;

  .select2-container--bc-smart-search .select2-selection {
    border-color: $bcSuccess;
  }
}