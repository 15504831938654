.buddy-request{

  &-heading{
    text-transform: none;
    color: $black;
  }

  &__details{
    margin-bottom: 15px;

    &__location{
      margin-bottom: 10px;
    }
  }

  &__actions{
    margin-bottom: 15px;
  }

  &__link{
    font-weight: bold;
  }

  .alert{
    margin-top: 20px;
    text-align: center;
  }

}