.table-wrapper {

  overflow-x: auto;

  .table-container {
    min-width:992px;
  }

  .table-container-small {
    min-width:768px;
  }

}