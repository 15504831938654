.ride-series {
  margin-top: $line-height-computed;
  float: none;

  .ride-series--ride {
    margin-bottom: $line-height-half;

    .date-time {
      width: $date-time-fixed-width;
      display: inline-block;
      text-align: left;
    }
  }

  .btn {
    margin-left: $h-space;
  }

  p {
    display: inline-block;
    padding-left: $h-space;
    margin-bottom: 0;
  }
}

@include under-md() {
  .ride-series p {
    margin-top: 5px;
  }
}


@include only-xs() {
  .ride-series {
    text-align: center;

    .ride-series--ride {
      text-align: left;

      .date-time {
        width: 100%;
        margin-bottom: $line-height-half;
      }
    }

    .btn {
      float: left;
      top: auto;
      margin-left: 0;

      + .btn {
        margin-left: $h-space;
      }
    }
  }
}