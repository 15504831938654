@use "sass:math";

$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{math.div($pixels, $context)}em;
}


@mixin centerer($top: 50%, $left: 50%, $horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: $top;
    left: $left;
    transform:translate(-50%, -50%);
  } @else if ($horizontal) {
    left: $left;
    transform:translate(-50%, 0);
  } @else if ($vertical) {
    top: $top;
    transform:translate(0, -50%);
  }
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}
// use to vertically align content next to .profile-container
@mixin profileContainerSibling($topOffset) {
  position: relative;
  top: $topOffset;
}