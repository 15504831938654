.ride-schedule__no-rides {
  padding: 20px;
  padding-top: 40px;
  background: $bcLightGrey;
  text-align: center;
  h3 {
    color: $black;
    font-size: $font-size-h2;
    text-transform: none;
  }

  &__content {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    @media (min-width: $screen-md) {
      margin-bottom: 0;
    }
  }

  &__search {
    display: inline-block;
    vertical-align: middle
  }
}