
button {
  border: none;
  background: none;
}

.btn{
  position: relative;
  box-shadow: $buttonShadow;
  border-radius: $border-radius-large;
  border-width: 3px;
  transition: all $transition ease;
  font-size: em(12);
  font-family: $headings-font-family;
  font-weight: normal;
  letter-spacing: 0px;
  letter-spacing: 0rem;
  text-transform: uppercase;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 6px 12px 5px 11px;
}

.btn--xs-stacked {
  + .btn {
    margin-left: $h-space;
  }
}

@include only-xs() {
  .btn--xs-stacked {
    margin-left: 0;
    margin-top: $line-height-half;
    float: left;
    clear: both;

    + .btn {
      margin-left: 0;
    }
  }
}

.btn-default,
.btn-primary{

  //just for reference
  //background-color: $bcPruple;
  //border-color: $bcPurple;
  color: $white;

  &:hover,
  &:focus {
    background-color: $white;
    border-color: $bcPurple;
    color: $bcPurple;
  }
}


.btn-white {

  background-color: $white!important;
  border-color: $white!important;
  color: $bcPurple!important;

  &:hover,
  &:focus {
    background-color: $bcPurple!important;
    border-color: $white!important;
    color: $white!important;
  }
}

.btn-purple-light {

  background-color: $bcLightPurple!important;
  border-color: $bcLightPurple!important;
  color: $white!important;

  &:hover,
  &:focus {
    background-color: $white!important;
    border-color: $bcLightPurple!important;
    color: $bcLightPurple!important;
  }
}


//red button
.btn-alternate{

  background-color: $bcRed!important;
  border-color: $bcRed!important;
  color: $white!important;

  &:hover,
  &:focus {
    background-color: $white!important;
    border-color: $bcRed!important;
    color: $bcRed!important;
  }
}


//breeze branded red
.btn-breeze {

  background-color: $breezeRed!important;
  border-color: $breezeRed!important;
  color: $white!important;

  &:hover,
  &:focus {
    background-color: $white!important;
    border-color: $breezeRed!important;
    color: $breezeRed!important;
  }
}


//ride social branded turquoise
.btn-ride {

  background-color: $rideTurquoise!important;
  border-color: $rideTurquoise!important;
  color: $white!important;

  &:hover,
  &:focus {
    background-color: $white!important;
    border-color: $rideTurquoise!important;
    color: $rideTurquoise!important;
  }
}


//primary button reversed for coloured bkgnd
.btn-reverse{

  background-color: $bcPurple!important;
  border-color: $white!important;
  color: $white!important;

  &:hover,
  &:focus {
    background-color: $white!important;
    border-color: $white!important;
    color: $bcPurple!important;
  }
}



//similar to button reverse with transparent bkgnd
.btn-keyline-white {

  background-color: transparent!important;
  border-color: $white!important;
  color: $white!important;

  &:hover,
  &:focus {
    background-color: $white!important;
    border-color: $white!important;
    color: $bcPurple!important;
  }
}

.btn-keyline-purple {

  background-color: transparent!important;
  border-color: $bcPurple!important;
  color: $bcPurple!important;

  &:hover,
  &:focus {
    background-color: $bcPurple!important;
    border-color: $bcPurple!important;
    color: $white!important;
  }
}


.btn-keyline-alternate {

  background-color: transparent!important;
  border-color: $bcRed!important;
  color: $bcRed!important;

  &:hover,
  &:focus {
    background-color: $bcRed!important;
    border-color: $bcRed!important;
    color: $white!important;
  }
}


.btn-keyline-breeze {

  background-color: transparent!important;
  border-color: $breezeRed!important;
  color: $breezeRed!important;

  &:hover,
  &:focus {
    background-color: $breezeRed!important;
    border-color: $breezeRed!important;
    color: $white!important;
  }
}

.btn-keyline-breeze {

  background-color: transparent!important;
  border-color: $rideTurquoise!important;
  color: $rideTurquoise!important;

  &:hover,
  &:focus {
    background-color: $rideTurquoise!important;
    border-color: $rideTurquoise!important;
    color: $white!important;
  }
}


.btn-danger{
  color: $white !important;
}


//makes any button into a circle
.btn-circle{
  width: 50px;
  height: 50px;
  padding: 0;
  font-size:em(22);
  line-height:initial;

  span {
    position: absolute;
    top:-3px;
    left:-3px;
    width:50px;
    height:50px;
    line-height:initial;

    &:before {
      position: absolute;
      top:50%;
      left:50%;
      width:26px;
      height:18px;
      transform:translate(-50%, -60%);
      line-height:initial;
    }
  }

  &.btn-circle-bottom-fixed {
    position: absolute;
    bottom: -25px;
    margin-left: -25px;
    z-index: 1;
    left: 50%;
  }
}

.clockpicker-am-pm-block {
  button {
    color: #000;
  }
}