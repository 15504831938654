// use mixin profileContainerSibling(TOPOFFSET) to vertically align content next to a .profile-container

.profile-container {
  display: block;
  position: relative;

  .profile-picture {
    display: block;
    border-radius:100%;
    max-width:65%;
    margin:0 auto;
    padding:10px;

    @media (min-width: $screen-ms-min) {
      max-width:inherit;
    }
  }

  .leader-roles {

    position: absolute;
    bottom: -12px;
    left:0;
    width:100%;
    text-align:center;
    margin:0;

    li {
      display:inline-block;
      width:30px;
      height:30px;
      background-color: $white;
      border-radius:100%;
      margin:0 5px;

      span {
        display: table;
      }

      img {
        width:30px;
        height:30px;
      }
    }
  }
}

.profile-container.profile--full-width {
  .profile-picture {
    max-width:100%;

    .ride-leaders__leader & {
      max-width:60%;
    }
  }
}

.profile-container.profile--width-auto {
  .profile-picture {
    max-width:inherit;
    width: auto;
  }
}

// profile link for img circle with text vertically centered
.profile-link {
  margin-bottom: $line-height-half;

  img,
  .profile-container img {
    @extend %imgBase;
  }

  a:first-child {
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  .profile-container {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width:35%;
    img {
      width:100%;
    }
  }

  .profile-link__text {
    display: inline-block;
    font-weight: bold;
    margin-left: $h-space;
  }
}