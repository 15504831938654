.create-ride,
.edit-ride {
  .panel{
    margin-bottom: 20px;
  }

  .panel-heading{
    background: none;

    [data-toggle="collapse"] {
      cursor: pointer;
    }

    h2 {
      display: inline-block;
      font-size: 28px;
    }

    .edit-profile{
      margin-left: 20px;
      text-decoration: underline;
    }
  }
}