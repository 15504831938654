%imgBase{
  max-width:inherit;
  width: auto;
  margin: 0;
}

img {
  width: 100%;
  height: auto;

  &.img--auto-width,
  .img--auto-width  {
    width: auto;
    margin: 0;
  }
}

.wysiwyg {
  img {
    max-width:100%!important;
  }
}

/* Stacla adds a 1px image to root of document, and the width: 100% makes a proper mess of things so disable for this case */
body > img {
  width: inherit;
}