.form-group {

  .form-control {

    &.parsley-success {
      color: $alert-success-text;
      background-color: $alert-success-bg;
      border: 1px solid $alert-success-border!important;
    }

    &.parsley-success.parsley--hide-success-style {
      color: $input-color;
      background-color: $input-bg;
      border: 1px solid $input-border !important;
    }

    &.parsley-error {
      border-color:$bcRed!important;
    }

  }




}


.parsley-error.error-control-wrapper {
  input, textarea, select {
    border-color:$bcRed!important;
  }
}

.parsley-errors-list {
  color: $bcRed;
  margin: 10px 0 3px;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 0.9em;
  opacity: 0;
  clear:both;

  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
}

.parsley-errors-list.filled {
  opacity: 1;
}

.error-wrapper {
  .parsley-errors-list.filled {
    //border:2px solid $bcRed;
  }
  li {
    //padding:5px;
    //font-size:em(20);
    text-align:left;
  }
}