.alert {
  a:not(.btn) {
    margin: 0;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
}

