.photo-gallery {
  .image-container {
    position: relative;

    &:hover{
      .btn-danger {
        opacity: 1;
      }
    }

    .btn-danger {
      padding: 5px 7px;
      opacity: 0;
      position: absolute;
      top: 15px;
      text-decoration: none;
    }
  }

  &__flag {
    left: 15px;
    border-radius: $block-border-radius;
  }

  &__remove {
    left: 90px;
  }
}